import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { FormTextField } from "../../components/forms/FormTextField";
import schemas from "../../data/schemas";
import { withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import { RootState, ResetPasswordRequest } from "../../store/config/types";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../store/config/ActionTypes";
import { resetPassword } from "../../store/action_creators/auth.actions";
import { connect, ConnectedProps } from "react-redux";
import { CustomSnackbar } from "../../components/common/CustomSnackbar";
import Cookies from "universal-cookie";

interface Values {
  email: string;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  resetPassword: ({ email }: ResetPasswordRequest) => dispatch(resetPassword({ email })),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function Reset({ auth, resetPassword, history }: PropsType) {
  const [recovering, setRecovering] = useState(false);

  const cookies = new Cookies();
  const hasToken = cookies.get("token") !== undefined;

  const goBack = () => {
    history.push("/login");
  };

  const submit = (values: Values) => {
    setRecovering(true);

    resetPassword({
      email: values.email,
    });
  };

  const closeSnack = () => {
    setRecovering(false);
  };

  return !hasToken ? (
    <div className="reset-password">
      <div className="title">
        <div className="left">
          <h2>Recuperar contraseña</h2>
          <p>Ingresá el mail con el cual registraste la alcancía y te enviaremos la contraseña.</p>
        </div>
        <span className="blue-link" onClick={goBack}>
          Volver
        </span>
      </div>
      <Formik initialValues={{ email: "" }} validationSchema={schemas.ResetPasswordSchema} onSubmit={submit}>
        <Form className="form reset-password-form">
          <Field name="email" component={FormTextField} type="text" placeholder="Email" />
          <div className="actions-row">
            <div />
            <Button color="secondary" variant="contained" type="submit">
              Recuperar contraseña
            </Button>
          </div>
        </Form>
      </Formik>
      <CustomSnackbar
        open={recovering && (auth.resetPasswordSuccess || auth.resetPasswordErrorMessage !== null)}
        message={
          auth.resetPasswordSuccess
            ? "Se envió el mail para restablecer la contraseña"
            : "Ocurrió un error al restablecer contraseña"
        }
        handleClose={closeSnack}
        type={auth.resetPasswordSuccess ? 0 : 1}
      />
    </div>
  ) : (
    <Redirect to="/dashboard" />
  );
}

export default connector(withRouter(Reset));
