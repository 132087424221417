import React, { useState, useEffect } from "react";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { urlHelper } from "../../helpers/urlHelper";
import { MoneyboxForm } from "../../components/forms/MoneyboxForm";
import { RootState } from "../../store/config/types";
import { connect, ConnectedProps } from "react-redux";
import { UserForm } from "../../components/forms/UserForm";
import { Dialog, DialogContent, Button } from "@material-ui/core";
import blackLogo from "../../assets/blackLogo.png";
import Cookies from "universal-cookie";

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  moneybox: state.moneybox,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function Register({ auth, moneybox, history }: PropsType) {
  const [email, setEmail] = useState<string>("");
  const [step, setStep] = useState(0);

  const cookies = new Cookies();
  const hasToken = cookies.get("token") !== undefined;

  useEffect(() => {
    if (step === 0) {
      let urlVars = urlHelper.parseQueryString();

      if (urlVars.step) {
        setStep(Number(urlVars.step));
      } else {
        setStep(1);
      }
    }
  }, [step, setStep]);

  const goBack = () => {
    history.push("/landing");
  };

  const firstStep = (
    <>
      <p className="subtitle">Información de acceso</p>
      <UserForm setEmail={setEmail} />
    </>
  );

  const secondStep = (
    <>
      <p className="subtitle">Tu primer alcancía</p>
      <MoneyboxForm />
    </>
  );

  return !hasToken ? (
    <div className="register">
      <div className="title">
        <div className="left">
          <h2>Creá una alcancía Teletón</h2>
          <p>{`Paso ${step} de 2`}</p>
        </div>
        <span className="blue-link" onClick={goBack}>
          Volver
        </span>
      </div>
      {step === 1 ? firstStep : null}
      {step === 1 && auth.registerSuccess ? (
        <Redirect to={`/mailSent/${email}`} />
      ) : null}
      {step === 2 ? secondStep : null}
      {step === 2 && moneybox.createMoneyboxSuccess ? (
        <Dialog className="register-moneybox" open={true} fullWidth maxWidth="xs">
          <DialogContent className="content">
            <img src={blackLogo} alt="logo" />
            <p className="welcome">Bienvenido a las alcancías Teletón</p>
            <span>Tu alcancía fue creada</span>
            <Button onClick={() => history.push("/login")} color="secondary" variant="contained">
              Continuar
            </Button>
            <div className="faqs">
              Conocé más sobre su funcionamiento en las{" "}
              <span className="blue-link" onClick={() => history.push("/faqs")}>
                Preguntas Frecuentes
              </span>
            </div>
          </DialogContent>
        </Dialog>
      ) : null}
    </div>
  ) : (
    <Redirect to="/dashboard" />
  );
}

export default connector(withRouter(Register));
