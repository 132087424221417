import { MoneyboxState, Moneybox } from '../config/types';
import {
  MoneyboxDataTypes,
  ADD_MONEYBOXES,
  LOADING_MONEYBOXES,
  MONEYBOXES_FAILED,
  LOADING_MONEYBOX,
  ADD_MONEYBOX,
  MONEYBOX_FAILED,
  CREATING_MONEYBOX,
  CREATE_MONEYBOX_SUCCESS,
  CREATE_MONEYBOX_FAILED,
  UPDATING_MONEYBOX,
  UPDATE_MONEYBOX_SUCCESS,
  UPDATE_MONEYBOX_FAILED,
  DELETING_MONEYBOX,
  DELETE_MONEYBOX_SUCCESS,
  VALIDATING_MONEYBOX,
  VALIDATE_MONEYBOX_SUCCESS,
  VALIDATE_MONEYBOX_FAILED,
  DELETE_MONEYBOX_FAILED,
  SELECT_MONEYBOX,
  LinksDataTypes,
  DELETE_LINK_SUCCESS,
  CREATE_LINK_SUCCESS,
  LogoutActionTypes,
  LOGOUT,
  CREATE_MONEYBOX_FINISH,
  DonationDataTypes,
  DONATION_FETCH_SUCCESS,
  CLEAR_MONEYBOX,
  MONEYBOXES_FETCHING_TOTALS,
  MONEYBOXES_FETCH_TOTALS_SUCCESS,
  MONEYBOXES_FETCH_TOTALS_ERROR,
} from '../config/ActionTypes';

export const initialState: MoneyboxState = {
  loadingMoneyboxes: false,
  moneyboxes: null,
  selectedMoneybox: null,
  moneyboxesErrorMessage: null,
  loadingMoneybox: false,
  moneybox: null,
  failedMoneyboxName: null,
  failedMoneyboxFantasyName: null,
  moneyboxErrorMessage: null,
  creatingMoneybox: false,
  createMoneyboxSuccess: false,
  createMoneyboxErrorMessage: null,
  updatingMoneybox: false,
  updateMoneyboxSuccess: false,
  updateMoneyboxErrorMessage: null,
  deletingMoneybox: false,
  deleteMoneyboxSuccess: false,
  deleteMoneyboxErrorMessage: null,
  validatingMoneybox: false,
  validateMoneyboxSuccess: false,
  validatedMoneybox: null,
  validatedLink: null,
  validateMoneyboxErrorMessage: null,
  fetchingTotals: false,
  totals: null,
  fetchTotalsErrors: null,
};

export function moneyboxReducer(
  state = initialState,
  action: MoneyboxDataTypes | DonationDataTypes | LinksDataTypes | LogoutActionTypes
): MoneyboxState {
  switch (action.type) {
    case LOADING_MONEYBOXES:
      return {
        ...state,
        loadingMoneyboxes: true,
        moneyboxes: null,
        moneyboxesErrorMessage: null,
      };

    case ADD_MONEYBOXES:
      let addedMoneyboxes = action.moneyboxes.map((m: Moneybox) => {
        if (m.links) {
          let newLinks = m.links;

          newLinks.sort((a, b) => {
            return a.value - b.value;
          });

          m.links = newLinks;
        }

        return m;
      });
      const firstActiveMoneybox = action.moneyboxes.find((m) => m.status === 0);
      return {
        ...state,
        loadingMoneyboxes: false,
        moneyboxes: addedMoneyboxes,
        selectedMoneybox: firstActiveMoneybox !== undefined ? firstActiveMoneybox : action.moneyboxes[0],
        moneyboxesErrorMessage: null,
      };

    case MONEYBOXES_FAILED:
      return {
        ...state,
        loadingMoneyboxes: false,
        moneyboxes: null,
        moneyboxesErrorMessage: action.error,
      };

    case LOADING_MONEYBOX:
      return {
        ...state,
        loadingMoneybox: true,
        moneybox: null,
        moneyboxErrorMessage: null,
      };

    case ADD_MONEYBOX:
      return {
        ...state,
        loadingMoneybox: false,
        moneybox: action.moneybox,
        moneyboxErrorMessage: null,
      };

    case MONEYBOX_FAILED:
      return {
        ...state,
        loadingMoneybox: false,
        moneybox: null,
        moneyboxErrorMessage: action.error,
      };

    case SELECT_MONEYBOX:
      return {
        ...state,
        selectedMoneybox: action.moneybox,
      };

    case CREATE_LINK_SUCCESS:
      let addedLinks = state.selectedMoneybox?.links ? state.selectedMoneybox?.links : [];

      addedLinks?.push(action.link);
      addedLinks?.sort((a, b) => {
        return a.value - b.value;
      });

      let theMoneybox: Moneybox = {
        ...state.selectedMoneybox!,
        links: addedLinks!,
      };

      let addedLinkMoneyboxes = state.moneyboxes;

      if (!state.selectedMoneybox?.links) {
        addedLinkMoneyboxes = addedLinkMoneyboxes!.map((l) => {
          if (l.id === state.selectedMoneybox?.id) {
            l.links = addedLinks;
          }

          return l;
        });
      }

      return {
        ...state,
        selectedMoneybox: theMoneybox,
        moneyboxes: addedLinkMoneyboxes,
      };

    case DELETE_LINK_SUCCESS:
      let existingLinks = state.selectedMoneybox?.links;

      existingLinks = existingLinks?.filter((l) => {
        if (l.code !== action.linkId) {
          return l;
        }
        return null;
      });

      let existingMoneybox: Moneybox = {
        ...state.selectedMoneybox!,
        links: existingLinks!,
      };

      return {
        ...state,
        selectedMoneybox: existingMoneybox,
      };

    case CREATING_MONEYBOX:
      return {
        ...state,
        creatingMoneybox: true,
        createMoneyboxSuccess: false,
        createMoneyboxErrorMessage: null,
      };

    case CREATE_MONEYBOX_SUCCESS:
      let newMoneyboxes: Moneybox[] = state.moneyboxes ? state.moneyboxes : [];
      newMoneyboxes.push(action.moneybox);

      return {
        ...state,
        creatingMoneybox: false,
        createMoneyboxSuccess: true,
        moneyboxes: newMoneyboxes,
        createMoneyboxErrorMessage: null,
        failedMoneyboxName: null,
        failedMoneyboxFantasyName: null,
      };

    case CREATE_MONEYBOX_FAILED:
      return {
        ...state,
        creatingMoneybox: false,
        createMoneyboxSuccess: false,
        createMoneyboxErrorMessage: action.error,
        failedMoneyboxName: action.failedMoneyboxName,
        failedMoneyboxFantasyName: action.failedMoneyboxName,
      };

    case CREATE_MONEYBOX_FINISH:
      return {
        ...state,
        creatingMoneybox: false,
        createMoneyboxSuccess: false,
        createMoneyboxErrorMessage: null,
      };

    case UPDATING_MONEYBOX:
      return {
        ...state,
        updatingMoneybox: true,
        updateMoneyboxSuccess: false,
        updateMoneyboxErrorMessage: null,
      };

    case UPDATE_MONEYBOX_SUCCESS:
      let updatedMoneyboxes = state.moneyboxes!.map((m) => {
        if (m.name === action.moneybox.name) {
          let returnedMoneybox: Moneybox = {
            ...m,
            fantasyName: action.moneybox.fantasyName,
            name: action.moneybox.name,
            imageUrl: action.moneybox.imageUrl,
          };

          return returnedMoneybox;
        }

        return m;
      });

      return {
        ...state,
        updatingMoneybox: false,
        moneyboxes: updatedMoneyboxes,
        selectedMoneybox: {
          ...state.selectedMoneybox!,
          fantasyName: action.moneybox.fantasyName,
          name: action.moneybox.name,
          imageUrl: action.moneybox.imageUrl,
        },
        updateMoneyboxSuccess: true,
        updateMoneyboxErrorMessage: null,
      };

    case UPDATE_MONEYBOX_FAILED:
      return {
        ...state,
        updatingMoneybox: false,
        updateMoneyboxSuccess: false,
        updateMoneyboxErrorMessage: action.error,
      };

    case DELETING_MONEYBOX:
      return {
        ...state,
        deletingMoneybox: true,
        deleteMoneyboxSuccess: false,
        deleteMoneyboxErrorMessage: null,
      };

    case DELETE_MONEYBOX_SUCCESS:
      return {
        ...state,
        deletingMoneybox: false,
        deleteMoneyboxSuccess: true,
        deleteMoneyboxErrorMessage: null,
      };

    case DELETE_MONEYBOX_FAILED:
      return {
        ...state,
        deletingMoneybox: false,
        deleteMoneyboxSuccess: false,
        deleteMoneyboxErrorMessage: action.error,
      };

    case VALIDATING_MONEYBOX:
      return {
        ...state,
        validatingMoneybox: true,
        validateMoneyboxSuccess: false,
        validateMoneyboxErrorMessage: null,
      };

    case VALIDATE_MONEYBOX_SUCCESS:
      return {
        ...state,
        validatingMoneybox: false,
        validateMoneyboxSuccess: true,
        validatedMoneybox: action.moneybox,
        validatedLink: action.link,
        validateMoneyboxErrorMessage: null,
      };

    case VALIDATE_MONEYBOX_FAILED:
      return {
        ...state,
        validatingMoneybox: false,
        validateMoneyboxSuccess: false,
        validateMoneyboxErrorMessage: action.error,
      };

    case CLEAR_MONEYBOX:
      return {
        ...state,
        validateMoneyboxSuccess: false,
        validatedMoneybox: null,
        validatedLink: null,
      };

    case DONATION_FETCH_SUCCESS:
      const fetchedMoneybox: Moneybox = {
        id: action.donationResponse.donationMoneyboxId,
        fantasyName: action.donationResponse.moneyboxFantasyName,
        admin: null,
        imageUrl: '',
        links: null,
        name: '',
        raisedAmount: 0,
        raisedAmountDollars: 0,
        status: 0,
        transactions: null,
      };

      return {
        ...state,
        validatedMoneybox: fetchedMoneybox,
        validateMoneyboxSuccess: fetchedMoneybox.fantasyName !== 'Generic',
      };

    case MONEYBOXES_FETCHING_TOTALS:
      return {
        ...state,
        fetchingTotals: true,
        totals: null,
        fetchTotalsErrors: null,
      };

    case MONEYBOXES_FETCH_TOTALS_SUCCESS:
      return {
        ...state,
        fetchingTotals: false,
        totals: action.response,
        fetchTotalsErrors: null,
      };

    case MONEYBOXES_FETCH_TOTALS_ERROR:
      return {
        ...state,
        fetchingTotals: false,
        totals: null,
        fetchTotalsErrors: action.error,
      };

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
