import * as Yup from 'yup';

const requiredString = 'Campo requerido';
const passwordFormatString = 'La contraseña debe tener al menos una mayúscula, una minúscula y un número';
const mailString = 'Formato de mail inválido';
const minAmountString = 'El monto debe ser mayor a cero';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;

const LoginSchema = Yup.object().shape({
  email: Yup.string().email(mailString).required(requiredString),
  password: Yup.string().matches(passwordRegex, passwordFormatString).required(requiredString),
});

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().email(mailString).required(requiredString),
});

const RegisterUserSchema = Yup.object().shape({
  email: Yup.string().email(mailString).required(requiredString),
  password: Yup.string().matches(passwordRegex, passwordFormatString).required(requiredString),
  repeatPassword: Yup.string()
    .matches(passwordRegex, passwordFormatString)
    .required(requiredString)
    .oneOf([Yup.ref('password')], 'Las contraseñas no coinciden'),
});

const ModifyPasswordSchema = Yup.object().shape({
  email: Yup.string().email(mailString).required(requiredString),
  oldPassword: Yup.string().matches(passwordRegex, passwordFormatString).required(requiredString),
  password: Yup.string().matches(passwordRegex, passwordFormatString).required(requiredString),
  repeatPassword: Yup.string()
    .matches(passwordRegex, passwordFormatString)
    .required(requiredString)
    .oneOf([Yup.ref('password')], 'Las contraseñas no coinciden'),
});

const MoneyboxSchema = Yup.object().shape({
  name: Yup.string()
    .required(requiredString)
    .matches(/^[a-zA-Z0-9_-]+$/, 'El nombre solo puede contener caracteres alfanúméricos y guiones.')
    .min(3, 'El nombre debe tener al menos 3 caracteres')
    .max(18, 'El largo del nombre no debe superar los 18 caracteres'),
  fantasyName: Yup.string()
    .required(requiredString)
    .max(50, 'El nombre fantasía no debe superar los 50 caracteres'),
});

const AmountSchema = Yup.object().shape({
  amount: Yup.number().required(requiredString).min(1, minAmountString),
});

const schemas = {
  LoginSchema,
  ResetPasswordSchema,
  RegisterUserSchema,
  ModifyPasswordSchema,
  MoneyboxSchema,
  AmountSchema,
};

export default schemas;
