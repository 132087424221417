import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RootState } from "../../store/config/types";
import { connect, ConnectedProps } from "react-redux";
import { MoneyboxForm } from "../../components/forms/MoneyboxForm";
import WarningIcon from "@material-ui/icons/Warning";

interface MoneyboxProfileProps {
  isNew: boolean;
}

const mapStateToProps = (state: RootState) => ({
  moneybox: state.moneybox,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & MoneyboxProfileProps & RouteComponentProps<any>;

function MoneyboxProfile({ isNew, moneybox, history }: PropsType) {
  const goBack = () => {
    history.goBack();
  };
console.log(moneybox.selectedMoneybox)
  return (
    <div className="moneyboxes">
      {(moneybox?.selectedMoneybox?.status === 99 && !isNew) && (
        <div className="disabled-warning">
          <WarningIcon color="primary" />
          Esta alcancía está inactiva. No podrás realizar cambios ni modificar sus links.
        </div>
      )}
      <div className="title">
        <div className="left">
          <h2>{isNew ? "Nueva alcancía" : "Alcancía"}</h2>
          <p>{isNew ? "Completá la información para crear una nueva alcancía" : "Información general"}</p>
        </div>
        <span className="blue-link" onClick={goBack}>
          Volver
        </span>
      </div>
      {moneybox.selectedMoneybox && !isNew ? (
        <MoneyboxForm selectedMoneybox={moneybox.selectedMoneybox} />
      ) : (
        <MoneyboxForm />
      )}
    </div>
  );
}

export default connector(withRouter(MoneyboxProfile));
