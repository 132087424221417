import { Dispatch } from 'redux';
import {
  FetchMoneyboxesTypes,
  LOADING_MONEYBOXES,
  ADD_MONEYBOXES,
  MONEYBOXES_FAILED,
  GetMoneyboxTypes,
  MONEYBOX_FAILED,
  ADD_MONEYBOX,
  LOADING_MONEYBOX,
  CreateMoneyboxTypes,
  CREATING_MONEYBOX,
  CREATE_MONEYBOX_SUCCESS,
  CREATE_MONEYBOX_FAILED,
  UpdateMoneyboxTypes,
  UPDATING_MONEYBOX,
  UPDATE_MONEYBOX_SUCCESS,
  UPDATE_MONEYBOX_FAILED,
  DeleteMoneyboxTypes,
  DELETE_MONEYBOX_FAILED,
  DELETE_MONEYBOX_SUCCESS,
  DELETING_MONEYBOX,
  ValidateMoneyboxTypes,
  VALIDATE_MONEYBOX_FAILED,
  VALIDATE_MONEYBOX_SUCCESS,
  VALIDATING_MONEYBOX,
  SelectMoneyboxTypes,
  SELECT_MONEYBOX,
  CREATE_MONEYBOX_FINISH,
  CLEAR_MONEYBOX,
  MoneyboxesFetchTotalsTypes,
  MONEYBOXES_FETCHING_TOTALS,
  MONEYBOXES_FETCH_TOTALS_SUCCESS,
  MONEYBOXES_FETCH_TOTALS_ERROR,
} from '../config/ActionTypes';
import { moneyboxService } from '../../services/moneybox.service';
import {
  Moneybox,
  MoneyboxRequest,
  CreateMoneyboxRequest,
  UpdateMoneyboxRequest,
  ValidationRequest,
  ValidationResponse,
  MoneyboxTotalsResponse,
} from '../config/types';

//#region Fetch moneyboxes

export const fetchMoneyboxes = () => {
  return (dispatch: Dispatch<FetchMoneyboxesTypes>) => {
    dispatch(loadingMoneyboxes());

    return moneyboxService.fetchMoneyboxes().then(
      (response) => {
        dispatch(addMoneyboxes(response!));
      },
      (error) => {
        dispatch(moneyboxesFailed(error));
      }
    );
  };
};

export const loadingMoneyboxes = (): FetchMoneyboxesTypes => ({
  type: LOADING_MONEYBOXES,
});

export const addMoneyboxes = (moneyboxes: Moneybox[]): FetchMoneyboxesTypes => ({
  type: ADD_MONEYBOXES,
  moneyboxes,
});

export const moneyboxesFailed = (error: string): FetchMoneyboxesTypes => ({
  type: MONEYBOXES_FAILED,
  error,
});

//#endregion

//#region Get moneybox

export const getMoneybox = ({ moneyboxId }: MoneyboxRequest) => {
  return (dispatch: Dispatch<GetMoneyboxTypes>) => {
    dispatch(loadingMoneybox());

    return moneyboxService.getMoneybox({ moneyboxId }).then(
      (response) => {
        dispatch(addMoneybox(response!));
      },
      (error) => {
        dispatch(moneyboxFailed(error));
      }
    );
  };
};

export const loadingMoneybox = (): GetMoneyboxTypes => ({
  type: LOADING_MONEYBOX,
});

export const addMoneybox = (moneybox: Moneybox): GetMoneyboxTypes => ({
  type: ADD_MONEYBOX,
  moneybox,
});

export const moneyboxFailed = (error: string): GetMoneyboxTypes => ({
  type: MONEYBOX_FAILED,
  error,
});

//#endregion

//#region Select moneybox

export const selectMoneybox = (moneybox: Moneybox): SelectMoneyboxTypes => ({
  type: SELECT_MONEYBOX,
  moneybox,
});

//#endregion

//#region Create moneybox

export const createMoneybox = ({ fantasyName, name, file }: CreateMoneyboxRequest) => {
  return (dispatch: Dispatch<CreateMoneyboxTypes>) => {
    dispatch(creatingMoneybox());

    let formData = new FormData();
    formData.append('fantasyName', fantasyName);
    formData.append('name', name);
    formData.append('file', file);

    return moneyboxService.createMoneybox(formData).then(
      (response) => {
        dispatch(createMoneyboxSuccess(response!));
      },
      (error: Error) => {
        dispatch(createMoneyboxFailed(error.message, name, fantasyName));
      }
    );
  };
};

export const creatingMoneybox = (): CreateMoneyboxTypes => ({
  type: CREATING_MONEYBOX,
});

export const createMoneyboxSuccess = (moneybox: Moneybox): CreateMoneyboxTypes => ({
  type: CREATE_MONEYBOX_SUCCESS,
  moneybox,
});

export const createMoneyboxFailed = (
  error: string,
  failedMoneyboxName: string,
  failedMoneyboxFantasyName: string
): CreateMoneyboxTypes => ({
  type: CREATE_MONEYBOX_FAILED,
  error,
  failedMoneyboxName,
  failedMoneyboxFantasyName,
});

export const finishMoneyboxCreation = (): CreateMoneyboxTypes => ({
  type: CREATE_MONEYBOX_FINISH,
});

//#endregion

//#region Update moneybox

export const updateMoneybox = ({ moneyboxId, fantasyName }: UpdateMoneyboxRequest) => {
  return (dispatch: Dispatch<UpdateMoneyboxTypes>) => {
    dispatch(updatingMoneybox());

    const updateMoneyboxRequest: UpdateMoneyboxRequest = {
      moneyboxId,
      fantasyName,
    };

    return moneyboxService.updateMoneybox(updateMoneyboxRequest).then(
      (response) => {
        dispatch(updateMoneyboxSuccess(response!));
      },
      (error: Error) => {
        dispatch(updateMoneyboxFailed(error.message));
      }
    );
  };
};

export const updatingMoneybox = (): UpdateMoneyboxTypes => ({
  type: UPDATING_MONEYBOX,
});

export const updateMoneyboxSuccess = (moneybox: Moneybox): UpdateMoneyboxTypes => ({
  type: UPDATE_MONEYBOX_SUCCESS,
  moneybox,
});

export const updateMoneyboxFailed = (error: string): UpdateMoneyboxTypes => ({
  type: UPDATE_MONEYBOX_FAILED,
  error,
});

//#endregion

//#region Delete moneybox

export const deleteMoneybox = ({ moneyboxId }: MoneyboxRequest) => {
  return (dispatch: Dispatch<DeleteMoneyboxTypes>) => {
    dispatch(deletingMoneybox());

    return moneyboxService.deleteMoneybox({ moneyboxId }).then(
      (response) => {
        if (response) {
          dispatch(deleteMoneyboxSuccess(moneyboxId));
        }
      },
      (error) => {
        dispatch(deleteMoneyboxFailed(error));
      }
    );
  };
};

export const deletingMoneybox = (): DeleteMoneyboxTypes => ({
  type: DELETING_MONEYBOX,
});

export const deleteMoneyboxSuccess = (moneyboxId: string): DeleteMoneyboxTypes => ({
  type: DELETE_MONEYBOX_SUCCESS,
  moneyboxId,
});

export const deleteMoneyboxFailed = (error: string): DeleteMoneyboxTypes => ({
  type: DELETE_MONEYBOX_FAILED,
  error,
});

//#endregion

//#region Validate moneybox

export const validateMoneybox = ({ code }: ValidationRequest) => {
  return (dispatch: Dispatch<ValidateMoneyboxTypes>) => {
    dispatch(validatingMoneybox());

    return moneyboxService.validateMoneybox({ code }).then(
      (response) => {
        dispatch(validateMoneyboxSuccess(response!));
      },
      (error) => {
        dispatch(validateMoneyboxFailed(error));
      }
    );
  };
};

export const validatingMoneybox = (): ValidateMoneyboxTypes => ({
  type: VALIDATING_MONEYBOX,
});

export const validateMoneyboxSuccess = (response: ValidationResponse): ValidateMoneyboxTypes => ({
  type: VALIDATE_MONEYBOX_SUCCESS,
  moneybox: response.moneybox,
  link: response.link,
});

export const validateMoneyboxFailed = (error: string): ValidateMoneyboxTypes => ({
  type: VALIDATE_MONEYBOX_FAILED,
  error,
});

export const clearMoneybox = (): ValidateMoneyboxTypes => ({
  type: CLEAR_MONEYBOX,
});

//#endregion

//#region Fetch moneyboxes totals

export const fetchMoneyboxesTotals =
  (businessName: string, from?: Date, to?: Date) => (dispatch: Dispatch<MoneyboxesFetchTotalsTypes>) => {
    dispatch(fetchingMoneyboxesATotals());
    return moneyboxService.getMoneyboxesTotals(businessName, from, to).then(
      (response) => {
        dispatch(fetchMoneyboxesTotalsSuccess(response!));
      },
      (error) => {
        dispatch(fetchMoneyboxesTotalsError(error));
      }
    );
  };

const fetchingMoneyboxesATotals = (): MoneyboxesFetchTotalsTypes => ({
  type: MONEYBOXES_FETCHING_TOTALS,
});

const fetchMoneyboxesTotalsSuccess = (response: MoneyboxTotalsResponse): MoneyboxesFetchTotalsTypes => ({
  type: MONEYBOXES_FETCH_TOTALS_SUCCESS,
  response,
});

const fetchMoneyboxesTotalsError = (error: string): MoneyboxesFetchTotalsTypes => ({
  type: MONEYBOXES_FETCH_TOTALS_ERROR,
  error,
});

//#endregion
