import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { authReducer } from "../reducers/auth";
import { AppActions } from "./ActionTypes";
import { donationsReducer } from "../reducers/donations";
import { linksReducer } from "../reducers/links";
import { moneyboxReducer } from "../reducers/moneybox";
import { issuersReducer } from "../reducers/issuers";

export const rootReducer = combineReducers({
  auth: authReducer,
  donations: donationsReducer,
  links: linksReducer,
  moneybox: moneyboxReducer,
  issuers: issuersReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(
  rootReducer,
  applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>)
);
