import { get, post } from "./base.service";
import {
  ConfirmDonationRequest,
  FetchDonationRequest,
  FinalizeDonationRequest,
  ConfirmDonationResponse,
  PaymentResponse,
  DonationResponse,
} from "../store/config/types";
import { endpoints } from "./endpoints";

export const donationService = {
  confirmDonation,
  fetchDonation,
  finalizeDonation,
};

async function confirmDonation(
  confirmDonationRequest: ConfirmDonationRequest
): Promise<ConfirmDonationResponse | undefined> {
  return await post<ConfirmDonationResponse>(`${endpoints["donationConfirm"]}`, confirmDonationRequest)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function fetchDonation({ donationId }: FetchDonationRequest): Promise<DonationResponse | undefined> {
  return await get<DonationResponse>(`${endpoints["donationBase"]}/${donationId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function finalizeDonation(
  finalizeDonationRequest: FinalizeDonationRequest
): Promise<PaymentResponse | undefined> {
  return await post<PaymentResponse>(endpoints["donationBase"], finalizeDonationRequest)
    .then((response) => {
      return response.parsedBody!;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
