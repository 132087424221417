import React, { useState, useEffect } from "react";
import logo from "../../assets/logo.png";
import MoodIcon from "@material-ui/icons/Mood";
import MoodBadIcon from "@material-ui/icons/MoodBad";
import Paper from "@material-ui/core/Paper";
import { RootState } from "../../store/config/types";
import { ConnectedProps, connect } from "react-redux";
import { urlHelper } from "../../helpers/urlHelper";
import blackLogo from "../../assets/blackLogo.png";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Image from "material-ui-image";
import { Lottie } from "@crello/react-lottie";
import loadAnimation from "../../assets/redLoading.json";
import { clearMoneybox } from "../../store/action_creators/moneybox.actions";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../store/config/ActionTypes";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PDFReceipt } from "../../components/result/PDFReceipt";

const mapStateToProps = (state: RootState) => ({
  donations: state.donations,
  moneybox: state.moneybox,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  clearMoneybox: () => dispatch(clearMoneybox()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function Result({ donations, moneybox, clearMoneybox, history }: PropsType) {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (donations.finalizeDonationErrorMessage) {
      setHasError(true);
    } else if (!donations.selectedDonation) {
      // history.push("/landing");
    }
  }, [setHasError, donations.finalizeDonationErrorMessage, donations.selectedDonation, history]);

  const goToHome = () => {
    clearMoneybox();
    history.push("/");
  };

  const actions = (
    <>
      <span className="blue-link" onClick={goToHome}>
        Ir a la página principal
      </span>
      <PDFDownloadLink
        document={<PDFReceipt moneybox={moneybox.validatedMoneybox!} donation={donations.selectedDonation!} />}
        fileName="Donación"
        style={{ textDecoration: "none" }}
      >
        {({ loading }) =>
          loading ? (
            "Cargando"
          ) : (
            <p className="help">
              <span className="blue-link">Descargar recibo</span>
            </p>
          )
        }
      </PDFDownloadLink>
    </>
  );

  return (
    <div className="result">
      <div className="result-content">
        <div className="result-header">
          <img src={logo} alt="Logo de la Teletón" />
        </div>
        {donations.finalizingDonation ? (
          <div className="result-loading">
            <Lottie height="200px" width="200px" config={{ animationData: loadAnimation, loop: true }} />
            <p>Estamos terminando de procesar tu donación.</p>
          </div>
        ) : !hasError ? (
          <div className="result-content">
            <div className="result-texts">
              <h1>¡Gracias!</h1>
              <p>En nombre de todos los niños Teletón</p>
            </div>
            {donations.selectedDonation ? (
              <Paper className="result-box">
                <Image
                  imageStyle={{ position: "inherit", height: 60, width: "auto" }}
                  style={{ display: "flex", paddingTop: 0 }}
                  src={
                    moneybox.validatedMoneybox && moneybox.validatedMoneybox.fantasyName !== "Generica"
                      ? urlHelper.buildImageUrl(moneybox.validatedMoneybox?.id)
                      : blackLogo
                  }
                  alt="alcancia"
                />
                <p className="result-moneybox-name">
                  Alcancía de{" "}
                  {moneybox.validatedMoneybox && moneybox.validatedMoneybox.fantasyName !== "Generica"
                    ? moneybox.validatedMoneybox?.fantasyName
                    : "Teletón"}
                </p>
                {moneybox.validatedMoneybox && moneybox.validatedMoneybox.fantasyName !== "Generica" ? (
                  <p className="result-description">Incluida tu participación, esta alcancía lleva donados…</p>
                ) : (
                  <p className="result-description">Tu donación se procesó exitosamente.</p>
                )}
                {moneybox.validatedMoneybox &&
                moneybox.validatedMoneybox.fantasyName === "Generica" ? null : donations.totals ? (
                  <div className="result-totals">
                    <MoodIcon />
                    {Boolean(donations.totals?.pesos) && (
                      <p className="result-total">
                        <span className="result-total-currency">$</span>
                        {donations.totals?.pesos}
                      </p>
                    )}
                    {Boolean(donations.totals?.pesos > 0 && donations.totals?.dollars > 0) && (
                      <p className="result-total-and">y</p>
                    )}
                    {donations.totals?.dollars > 0 && (
                      <p className="result-total">
                        <span className="result-total-currency">US$</span>
                        {donations.totals?.dollars}
                      </p>
                    )}
                  </div>
                ) : (
                  <Lottie height="100px" width="100px" config={{ animationData: loadAnimation, loop: true }} />
                )}
                <hr />
                {actions}
              </Paper>
            ) : (
              <Paper className="result-box">
                <MoodIcon />
                <h2>Tu donación fue realizada</h2>
                <hr />
                {actions}
              </Paper>
            )}
          </div>
        ) : (
          <div className="result-content result-content-error">
            <Paper className="result-box">
              <div className="result-box-header">
                <MoodBadIcon />
                <div className="result-box-header-text">
                  <h2>Hubo un problema</h2>
                  <p className="result-error">La donación no se pudo realizar</p>
                </div>
              </div>
              <p>Hubo un problema al procesar el pago y no se pudo completar tu donación.</p>
              <hr />
              <div className="result-box-actions">
                <span className="blue-link" onClick={() => history.push("/checkout")}>
                  Volver a intentar
                </span>
                <span className="blue-link" onClick={() => history.push("/landing")}>
                  Cancelar
                </span>
              </div>
            </Paper>
          </div>
        )}
      </div>
    </div>
  );
}

export default connector(withRouter(Result));
