import { Link } from "@material-ui/core";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import icon from "../../../assets/icon.png";

function Footer({ history }: RouteComponentProps) {
  const goToFaqs = () => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth",
    });

    history.push("/faqs");
  };

  return (
    <div className="footer">
      <div className="footer-content">
        <div className="logo">
          <img src={icon} alt="icon" />
          <span>{`© ${new Date().getFullYear()} Teletón Uruguay`}</span>
        </div>
        <div className="questions">
          <span>¿Necesito una alcancía para poder donar?</span>
          <span>¿Cuánto de mi donación llega a la Teletón?</span>
          <span className="blue-link" onClick={goToFaqs}>
            Encontrá las respuestas en nuestras preguntas frecuentes
          </span>
        </div>
        <div className="mobile-faqs">
          <span className="blue-link" onClick={goToFaqs}>Preguntas frecuentes</span>
        </div>
        <span className="blue-link">
          <Link href="https://www.teleton.org.uy" color="secondary" target="_blank" >
            www.teleton.org.uy
          </Link>
        </span>
      </div>
    </div>
  );
}

export default withRouter(Footer);
