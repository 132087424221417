import { AuthState } from '../config/types'
import {
  LOGGING_IN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  PASSWORD_CHANGING,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CHANGE_ERROR,
  RESETING_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  AuthDataTypes,
  REGISTERING,
  CHECK_EMAIL_ERROR,
  CHECK_EMAIL_SUCCESS,
  CHECKING_EMAIL,
  REGISTER_ERROR,
  REGISTER_SUCCESS,
  CONFIRM_RESET_PASSWORD_ERROR,
  CONFIRM_RESET_PASSWORD_SUCCESS,
  CONFIRMING_RESET_PASSWORD,
  ADD_MONEYBOXES,
  FetchMoneyboxesTypes,
  LOGOUT,
  CONFIRMING_EMAIL,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL_ERROR,
  RESENDING_EMAIL,
  RESEND_EMAIL_SUCCESS,
  RESEND_EMAIL_ERROR,
} from '../config/ActionTypes'

export const initialState: AuthState = {
  loggingIn: false,
  loginEmail: null,
  loggedIn: false,
  user: null,
  logInErrorMessage: null,
  registering: false,
  registeredUser: null,
  registerSuccess: false,
  registerErrorMessage: null,
  checkingEmailAvailability: false,
  checkEmailAvailabilitySuccess: false,
  checkEmailAvailabilityErrorMessage: null,
  changingPassword: false,
  changePasswordSuccess: false,
  changePasswordErrorMessage: null,
  resetingPassword: false,
  resetPasswordSuccess: false,
  resetPasswordErrorMessage: null,
  confirmingResetPassword: false,
  confirmResetPasswordSuccess: false,
  confirmResetPasswordErrorMessage: null,
  confirmingEmail: false,
  confirmEmailSuccess: false,
  confirmEmailErrorMessage: null,
  resendingEmail: false,
  resendEmailSuccess: false,
  resendEmailErrorMessage: null,
}

export function authReducer(
  state = initialState,
  action: AuthDataTypes | FetchMoneyboxesTypes
): AuthState {
  switch (action.type) {
    case LOGGING_IN:
      return {
        ...state,
        loggingIn: true,
        loginEmail: action.email,
        loggedIn: false,
        user: null,
        logInErrorMessage: null,
      }

    case LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        logInErrorMessage: null,
      }

    case LOGIN_ERROR:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        user: null,
        logInErrorMessage: action.error,
      }

    case ADD_MONEYBOXES: {
      return {
        ...state,
        loggedIn: true,
      }
    }

    case REGISTERING:
      return {
        ...state,
        registering: true,
        registeredUser: null,
        registerSuccess: false,
        registerErrorMessage: null,
      }

    case REGISTER_SUCCESS:
      return {
        ...state,
        registering: false,
        registeredUser: action.user,
        registerSuccess: true,
        registerErrorMessage: null,
      }

    case REGISTER_ERROR:
      return {
        ...state,
        registering: false,
        registeredUser: null,
        registerSuccess: false,
        registerErrorMessage: action.error,
      }

    case CHECKING_EMAIL:
      return {
        ...state,
        checkingEmailAvailability: true,
        checkEmailAvailabilitySuccess: false,
        checkEmailAvailabilityErrorMessage: null,
      }

    case CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        checkingEmailAvailability: false,
        checkEmailAvailabilitySuccess: true,
        checkEmailAvailabilityErrorMessage: null,
      }

    case CHECK_EMAIL_ERROR:
      return {
        ...state,
        checkingEmailAvailability: false,
        checkEmailAvailabilitySuccess: false,
        checkEmailAvailabilityErrorMessage: action.error,
      }

    case PASSWORD_CHANGING:
      return {
        ...state,
        changingPassword: true,
        changePasswordSuccess: false,
        changePasswordErrorMessage: null,
      }

    case PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        changingPassword: false,
        changePasswordSuccess: true,
        changePasswordErrorMessage: null,
      }

    case PASSWORD_CHANGE_ERROR:
      return {
        ...state,
        changingPassword: false,
        changePasswordSuccess: false,
        changePasswordErrorMessage: action.error,
      }

    case RESETING_PASSWORD:
      return {
        ...state,
        resetingPassword: true,
        resetPasswordSuccess: false,
        resetPasswordErrorMessage: null,
      }

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetingPassword: false,
        resetPasswordSuccess: true,
        resetPasswordErrorMessage: null,
      }

    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetingPassword: false,
        resetPasswordSuccess: false,
        resetPasswordErrorMessage: action.error,
      }

    case CONFIRMING_RESET_PASSWORD:
      return {
        ...state,
        confirmingResetPassword: true,
        confirmResetPasswordSuccess: false,
        confirmResetPasswordErrorMessage: null,
      }

    case CONFIRM_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        confirmingResetPassword: true,
        confirmResetPasswordSuccess: true,
        confirmResetPasswordErrorMessage: null,
      }

    case CONFIRM_RESET_PASSWORD_ERROR:
      return {
        ...state,
        confirmingResetPassword: false,
        confirmResetPasswordSuccess: false,
        confirmResetPasswordErrorMessage: action.error,
      }

    case CONFIRMING_EMAIL:
      return {
        ...state,
        confirmingEmail: true,
        confirmEmailSuccess: false,
        confirmEmailErrorMessage: null,
      }

    case CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        confirmingEmail: false,
        confirmEmailSuccess: true,
        confirmEmailErrorMessage: null,
      }

    case CONFIRM_EMAIL_ERROR:
      return {
        ...state,
        confirmingEmail: false,
        confirmEmailSuccess: false,
        confirmEmailErrorMessage: action.error,
      }

    case RESENDING_EMAIL:
      return {
        ...state,
        resendingEmail: true,
        resendEmailSuccess: false,
        resendEmailErrorMessage: null,
      }

    case RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        resendingEmail: false,
        resendEmailSuccess: true,
        resendEmailErrorMessage: null,
        logInErrorMessage: null,
      }

    case RESEND_EMAIL_ERROR:
      return {
        ...state,
        resendingEmail: false,
        resendEmailSuccess: false,
        resendEmailErrorMessage: action.error,
      }

    case LOGOUT:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
