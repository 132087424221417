import React, { useState, useEffect } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterCenterFocusIcon from "@material-ui/icons/FilterCenterFocus";
import LinksCreation from "./LinksCreation";
import { DeleteDialog } from "../../common/DeleteDialog";
import { Link, RootState, DeleteLinkRequest } from "../../../store/config/types";
import { Currency } from "../../../store/config/enums";
import { connect, ConnectedProps } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../store/config/ActionTypes";
import { deleteLink } from "../../../store/action_creators/links.actions";
import LinkOption from "./LinkOption";
import DownloadQRList from "../DownloadQR/DownloadQRList";
import { CustomSnackbar } from "../../common/CustomSnackbar";
import { RouteComponentProps, withRouter } from "react-router-dom";

const mapStateToProps = (state: RootState) => ({
  moneybox: state.moneybox,
  links: state.links,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  deleteLink: ({ moneyboxId, linkId }: DeleteLinkRequest) => dispatch(deleteLink({ moneyboxId, linkId })),
});

interface MoneyboxLinksProps {
  existingLinks: Link[];
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any> & MoneyboxLinksProps;

function MoneyboxLinks({ links, moneybox, deleteLink, existingLinks, history }: PropsType) {
  const [selectedToDelete, setSelectedToDelete] = useState<Link | null>(null);
  const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null);

  const [deletePesosOpened, setDeletePesosOpened] = useState<boolean>(false);
  const [deleteDollarsOpened, setDeleteDollarsOpened] = useState<boolean>(false);
  const [deleteLinkOpened, setDeleteLinkOpened] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const [addedPesosOptions, setAddedPesosOptions] = useState<Link[]>(
    existingLinks
      ? existingLinks.filter((l) => {
          return l.currency === Currency.PESO;
        })
      : []
  );
  const [addedDollarsOptions, setAddedDollarsOptions] = useState<Link[]>(
    existingLinks
      ? existingLinks.filter((l) => {
          return l.currency === Currency.DOLAR;
        })
      : []
  );

  const setLinkToDelete = (link: Link, currency: string) => {
    setSelectedToDelete(link);
    setSelectedCurrency(currency);
    setDeleteLinkOpened(true);
  };

  const deleteAllPesos = () => {
    addedPesosOptions.forEach((o) => {
      deleteLink({
        moneyboxId: moneybox.selectedMoneybox ? moneybox.selectedMoneybox.id : "",
        linkId: o.code,
      });
    });
    setOpenSnackbar(true);
  };

  const deleteAllDollars = () => {
    addedDollarsOptions.forEach((o) => {
      deleteLink({
        moneyboxId: moneybox.selectedMoneybox ? moneybox.selectedMoneybox.id : "",
        linkId: o.code,
      });
    });
    setOpenSnackbar(true);
  };

  const confirmLinkDelete = () => {
    deleteLink({
      moneyboxId: moneybox.selectedMoneybox ? moneybox.selectedMoneybox.id : "",
      linkId: selectedToDelete ? selectedToDelete.code : "",
    });
    setOpenSnackbar(true);
  };

  // const goToFaqs = () => {
  //   window.scrollTo({
  //     left: 0,
  //     top: 0,
  //     behavior: "smooth",
  //   });

  //   history.push("/faqs");
  // };

  useEffect(() => {
    setAddedPesosOptions(
      existingLinks
        ? existingLinks.filter((l) => {
            return l.currency === Currency.PESO;
          })
        : []
    );
    setAddedDollarsOptions(
      existingLinks
        ? existingLinks.filter((l) => {
            return l.currency === Currency.DOLAR;
          })
        : []
    );
  }, [setAddedPesosOptions, setAddedDollarsOptions, existingLinks, links.createLinkSuccess, links.deleteLinkSuccess]);

  return (
    <>
      <div className="moneybox-links">
        <div className="links-heading">
          <div className="title">
            <h2>Links</h2>
            <span
              className="blue-link icon-blue-link"
              style={[...addedPesosOptions, ...addedDollarsOptions].length <= 0 ? { display: "none" } : {}}
            >
              <FilterCenterFocusIcon className="icon" fontSize="inherit" />{" "}
              <DownloadQRList
                selectedMoneybox={moneybox.selectedMoneybox!}
                selectedLinks={[...addedPesosOptions, ...addedDollarsOptions]}
              />
            </span>
          </div>
          <p>
            Cada link define un monto de donación. Se pueden activar o desactivar e incluso crear valores específicos.
            Al compartir un link o el código QR asociado al mismo, se está invitando a donar por la cantidad en él
            definida. Por más información sobre cómo funcionan los links ver nuestras{" "}
            <span className="blue-link">Preguntas frecuentes.</span>
          </p>
        </div>
        {addedPesosOptions.length > 0 ? (
          <div className="pesos-links">
            <div className="links-title">
              <span className="title">Pesos</span>
              <span className="blue-link icon-blue-link" onClick={() => setDeletePesosOpened(true)}>
                <DeleteIcon className="icon" fontSize="inherit" /> Eliminar todos los links en pesos
              </span>
              <DeleteDialog
                open={deletePesosOpened}
                setOpen={setDeletePesosOpened}
                message="¿Eliminar todos los links en pesos?"
                deleteAction={deleteAllPesos}
              />
            </div>
            <div className="links">
              {addedPesosOptions.map((option, index) => {
                return <LinkOption key={index} setLinkToDelete={setLinkToDelete} link={option} />;
              })}
            </div>
          </div>
        ) : null}
        {addedDollarsOptions.length > 0 ? (
          <div className="dollars-links">
            <div className="links-title">
              <span className="title"> Dólares</span>
              <span className="green-link icon-blue-link" onClick={() => setDeleteDollarsOpened(true)}>
                <DeleteIcon className="icon" fontSize="inherit" />
                Eliminar todos los links en dólares
              </span>
              <DeleteDialog
                open={deleteDollarsOpened}
                setOpen={setDeleteDollarsOpened}
                message="¿Eliminar todos los links en dólares?"
                deleteAction={deleteAllDollars}
              />
            </div>
            <div className="links">
              {addedDollarsOptions.map((option, index) => {
                return <LinkOption key={index} setLinkToDelete={setLinkToDelete} link={option} />;
              })}
            </div>
          </div>
        ) : null}
      </div>
      <DeleteDialog
        open={deleteLinkOpened}
        setOpen={setDeleteLinkOpened}
        message={`¿Eliminar el link de ${selectedCurrency}${selectedToDelete?.value}?`}
        deleteAction={confirmLinkDelete}
      />
      <CustomSnackbar
        open={openSnackbar && links.deleteLinkErrorMessage !== null}
        message="Ocurrió un error al borrar el/los link/s"
        handleClose={() => setOpenSnackbar(false)}
        type={1}
      />
      <LinksCreation addedPesosOptions={addedPesosOptions} addedDollarsOptions={addedDollarsOptions} />
    </>
  );
}

export default connector(withRouter(MoneyboxLinks));
