import React from "react";
import "./styles/app.scss";
import { Provider } from "react-redux";
import { store } from "./store/config/configureStore";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import { BrowserRouter } from "react-router-dom";
import Main from "./screens/Main";
import { theme } from "./styles/theme";
import { ErrorBoundary } from "./components/common/ErrorBoundary";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <BrowserRouter>
            <CssBaseline />
            <Main />
          </BrowserRouter>
        </ErrorBoundary>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
