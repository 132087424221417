import React, { useEffect, useState } from "react";
import icon from "../../assets/icon.png";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FinalizeDonationRequest, RootState } from "../../store/config/types";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../store/config/ActionTypes";
import {
  clearInstrument,
  fetchDonation,
  finalizeDonation,
} from "../../store/action_creators/donations.actions";
import { connect, ConnectedProps } from "react-redux";
import { currencyHelper } from "../../helpers/currencyHelper";
import { Currency } from "../../store/config/enums";
import { urlHelper } from "../../helpers/urlHelper";
import Image from "material-ui-image";
import { uuid } from "uuidv4";
import WarningIcon from "@material-ui/icons/Warning";

const mapStateToProps = (state: RootState) => ({
  donations: state.donations,
  moneybox: state.moneybox,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, any, AppActions>
) => ({
  fetchDonation: (donationId: string) => dispatch(fetchDonation(donationId)),
  finalizeDonation: ({ donationId, fingerprint }: FinalizeDonationRequest) =>
    dispatch(finalizeDonation({ donationId, fingerprint })),
  clearInstrument: () => dispatch(clearInstrument()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function Checkout({
  donations,
  moneybox,
  fetchDonation,
  finalizeDonation,
  clearInstrument,
  history,
}: PropsType) {
  const hasMoneybox =
    moneybox.validatedMoneybox !== null &&
    moneybox.validatedMoneybox.fantasyName !== "Generic";
  const [fingerprintLoaded, setFingerprintLoaded] = useState<boolean>(false);

  useEffect(() => {
    loadVisaDeviceFingerprint(() => {
      setFingerprintLoaded(true);
    });
  });

  const isTest =
    window.location.host === "localhost:3000" ||
    window.location.host === "teleton.netlify.app";
  const environmentId = isTest ? "1snn5n9w" : "k8vif92e";
  const commerceId = isTest ? "123" : "visanetuy_px_2048256716";
  const fingerprintId = uuid();
  const visaFingerprint = commerceId + fingerprintId;

  const loadVisaDeviceFingerprint = (callback: any) => {
    const existingScript = document.getElementById("visaDeviceFingerprint");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = `https://h.online-metrix.net/fp/tags.js?org_id=${environmentId}&session_id=${visaFingerprint}`;
      script.id = "visaDeviceFingerprint";
      document.body.appendChild(script);

      script.onload = () => {
        if (callback) callback();
      };
    }

    if (existingScript && callback) callback();
  };

  useEffect(() => {
    if (
      donations.donationId &&
      !donations.fetchingDonation &&
      !donations.fetchDonationErrorMessage &&
      !donations.issuer
    ) {
      fetchDonation(donations.donationId);
    }
  }, [
    donations.donationId,
    donations.fetchingDonation,
    donations.fetchDonationErrorMessage,
    donations.issuer,
    fetchDonation,
  ]);

  useEffect(() => {
    if (!donations.donationId) {
      redirectTo("/landing");
    }
  });

  const redirectTo = (path: string) => {
    history.push(path);
  };

  const changeInstrument = () => {
    clearInstrument();
    redirectTo("/landing");
  };

  const pay = () => {
    console.log("Fingerprint loaded: " + fingerprintLoaded);
    if (donations.donationId) {
      finalizeDonation({
        donationId: donations.donationId,
        fingerprint: isTest
          ? "d1cab7e203194d09972b7fb3d4657fc8"
          : fingerprintId,
      });
      redirectTo("/result");
    }
  };

  const isFirefox = false; //navigator.userAgent.search("Firefox") > -1;

  return (
    <div className="checkout">
      {hasMoneybox ? (
        <div className="moneybox-data">
          <Image
            imageStyle={{ position: "inherit", height: 80, width: "auto" }}
            style={{ display: "flex", paddingTop: 0 }}
            src={urlHelper.buildImageUrl(
              moneybox.validatedMoneybox ? moneybox.validatedMoneybox?.id : ""
            )}
            alt="alcancia"
          />
          <p className="name">
            {moneybox.validatedMoneybox &&
            moneybox.validatedMoneybox.fantasyName !== "Generica"
              ? moneybox.validatedMoneybox.fantasyName
              : "Alcancía de Teletón"}
          </p>
        </div>
      ) : (
        <img className="teleton-icon" src={icon} alt="icon" />
      )}
      <h2>Último paso</h2>
      <p>Ya podés confirmar la donación</p>
      {donations.issuer && donations.issuer.issuerName === "Visa" ? (
        <p className="fingerprint-warning">
          <WarningIcon />
          <span>
            Si tu navegador es Firefox, es posible que tengas bloqueado el uso
            de fingerprints y no puedas completar exitosamente esta transacción.
          </span>
          {isFirefox ? (
            <a
              className="blue-link"
              target="_blank"
              rel="noopener noreferrer"
              href="about:preferences#privacy"
            >
              Cómo hacerlo en Firefox
            </a>
          ) : null}
        </p>
      ) : null}
      {donations.selectedDonation?.currency === Currency.DOLAR ? (
        <p className="green-text">en dólares U$S</p>
      ) : null}
      <div
        className={`checkout-button ${
          donations.selectedDonation?.currency === Currency.DOLAR
            ? "dollar-checkout-button"
            : ""
        }`}
        onClick={pay}
      >
        <p>
          {`Confirmar donación por ${currencyHelper.getCurrencyToken(
            donations.selectedDonation
              ? donations.selectedDonation?.currency
              : Currency.PESO
          )} `}
          <b>
            {donations.selectedDonation
              ? donations.selectedDonation.value
              : 101}
          </b>
        </p>
      </div>
      {donations.issuer ? (
        <div className="payment-method">
          <Image
            imageStyle={{ position: "inherit", height: 20, width: "auto" }}
            style={{ display: "flex", paddingTop: 0 }}
            src={donations.issuer.issuerImageUrl}
            alt="sello"
          />
          <span>{`Terminación ${donations.issuer.name.slice(
            donations.issuer.name.length - 4
          )}`}</span>
        </div>
      ) : null}
      <p className="blue-link" onClick={changeInstrument}>
        Cancelar
      </p>
    </div>
  );
}

export default connector(withRouter(Checkout));
