import { BASE_URL } from './config';

export const endpoints = {
  moneyboxBase: `${BASE_URL}/moneybox`,
  moneyboxValidate: `${BASE_URL}/moneybox/validate`,
  getMoneyboxesTotals: `${BASE_URL}/moneybox/totals`,
  donationBase: `${BASE_URL}/donation`,
  donationConfirm: `${BASE_URL}/donation/confirm`,
  authLogin: `${BASE_URL}/auth/authenticate`,
  authRegister: `${BASE_URL}/auth`,
  authEmailVerification: `${BASE_URL}/auth/verification`,
  authModifyPassword: `${BASE_URL}/auth/password`,
  authResetPassword: `${BASE_URL}/auth/password/reset`,
  authConfirmEmail: `${BASE_URL}/auth/confirmation`,
  authResendEmail: `${BASE_URL}/auth/resendMail`,
  issuers: `${BASE_URL}/issuers/supported`,
};
