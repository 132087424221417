import React, { useState } from "react";
import { DollarsDialog } from "../../common/DollarsDialog";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Currency } from "../../../store/config/enums";
import {
  confirmDonation,
  setDonation,
} from "../../../store/action_creators/donations.actions";
import {
  ConfirmDonationRequest,
  Donation,
  RootState,
} from "../../../store/config/types";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../store/config/ActionTypes";
import { connect, ConnectedProps } from "react-redux";
import { currencyHelper } from "../../../helpers/currencyHelper";
import { options } from "../../../data/options";
import { DonationOption, Values } from "./types";
import SpecificAmountOption from "./SpecificAmountOption";
import SelectedOption from "./SelectedOption";
import DonateOption from "./DonateOption";
import SupportedIssuers from "../../common/SupportedIssuers/SupportedIssuers";

interface DonationOptionsProps {
  invitation?: string | null | undefined;
  selectedOption?: DonationOption | null;
}

const mapStateToProps = (state: RootState) => ({
  moneybox: state.moneybox,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, any, AppActions>
) => ({
  setDonation: (donation: Donation) => dispatch(setDonation(donation)),
  confirmDonation: (confirmDonationRequest: ConfirmDonationRequest) =>
    dispatch(confirmDonation(confirmDonationRequest)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux &
  DonationOptionsProps &
  RouteComponentProps<any>;

function DonationOptions({
  moneybox,
  confirmDonation,
  invitation,
  selectedOption,
  history,
}: PropsType) {
  const [dollarsOpen, setDollarsOpen] = useState<boolean>(false);
  const [localDonation, setLocalDonation] =
    useState<ConfirmDonationRequest | null>(null);
  const [theSelectedOption, setTheSelectedOption] =
    useState<DonationOption | null>(selectedOption ? selectedOption! : null);

  const advanceToDetails = ({ moneyboxId, currency, value }: Donation) => {
    const confirmDonationRequest: ConfirmDonationRequest = {
      moneyboxId,
      currency,
      value,
      redirectUrl: `${window.location.origin}/redirect`,
    };

    if (currency === Currency.PESO) {
      confirmDonation(confirmDonationRequest);
      history.push("/plexo");
    } else {
      setLocalDonation(confirmDonationRequest);
      setDollarsOpen(true);
    }
  };

  const submit = (values: Values) => {
    advanceToDetails({
      moneyboxId: moneybox.validatedMoneybox
        ? moneybox.validatedMoneybox?.id
        : options.anonMoneybox,
      currency: values.currency,
      value: Number(values.amount),
    });
  };

  const confirmDollarsDonation = () => {
    confirmDonation(localDonation!);
    history.push("/plexo");
  };

  return (
    <div className="donation-landing">
      <h2>Realizá tu donación online</h2>
      {invitation ? (
        <p className="invitation">
          {`${invitation} te invita a realizar una donación ${
            theSelectedOption
              ? `de ${currencyHelper.getCurrencyToken(
                  theSelectedOption.currency
                )}${theSelectedOption.amount}`
              : ""
          } mediante su `}
          <b>alcancía Teletón</b>
        </p>
      ) : null}
      <p>
        {theSelectedOption
          ? "Para continuar con el proceso de donación hacé click en el botón a continuación..."
          : "Comenzá eligiendo un monto a continuación..."}
      </p>
      {theSelectedOption ? (
        <SelectedOption
          advanceToDetails={advanceToDetails}
          theSelectedOption={theSelectedOption}
          setTheSelectedOption={setTheSelectedOption}
          moneybox={moneybox}
        />
      ) : (
        <>
          <div className="pesos-options">
            <p className="options-title">Pesos</p>
            <div className="options">
              {options.donatePesosOptions.map(
                (option: number, index: number) => {
                  return (
                    <DonateOption
                      key={index}
                      moneybox={moneybox}
                      currency={Currency.PESO}
                      option={option}
                      advanceToDetails={advanceToDetails}
                    />
                  );
                }
              )}
            </div>
          </div>
          <div className="dollars-options">
            <p className="options-title">Dólares</p>
            <div className="options">
              {options.donateDollarsOptions.map(
                (option: number, index: number) => {
                  return (
                    <DonateOption
                      key={index}
                      moneybox={moneybox}
                      currency={Currency.DOLAR}
                      option={option}
                      advanceToDetails={advanceToDetails}
                    />
                  );
                }
              )}
            </div>
          </div>
          <SpecificAmountOption submit={submit} />
          <SupportedIssuers />
        </>
      )}
      <DollarsDialog
        open={dollarsOpen}
        setOpen={setDollarsOpen}
        donateAction={confirmDollarsDonation}
        donation={localDonation}
      />
    </div>
  );
}

export default connector(withRouter(DonationOptions));
