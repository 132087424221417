import React, { useState } from "react";
import logo from "../../../assets/logo.png";
import { RouteComponentProps, withRouter } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { urlHelper } from "../../../helpers/urlHelper";
import BurgerToggle from "./BurgerToggle";
import { Slide, DialogContent, Dialog, DialogTitle, Button } from "@material-ui/core";
import Cookies from "universal-cookie";
import { logout } from "../../../store/action_creators/auth.actions";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../store/config/types";
import { AppActions } from "../../../store/config/ActionTypes";
import { connect, ConnectedProps } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";

const options = [
  {
    name: "Nueva alcancía",
    icon: <AddCircleIcon className="icon" />,
    route: "/newMoneybox",
  },
  {
    name: "Mis alcancías",
    icon: <ArrowDropDownCircleIcon className="icon" />,
    route: "/moneyboxes",
  },
  {
    name: "Usuario",
    icon: <PersonIcon className="icon" />,
    route: "/user",
  },
  {
    name: "Salir",
    icon: <ExitToAppIcon className="icon" />,
    route: "/login",
  },
];

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  logout: () => dispatch(logout()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function Header({ auth, logout, history }: PropsType) {
  const [open, setOpen] = useState<boolean>(false);
  const [mobileMenuOpened, setMobileMenuOpened] = useState<boolean>(false);
  const isPublicPage = urlHelper.isOnPublicUrl(history.location.pathname);

  const goHome = () => {
    if (window.location.pathname !== "/dashboard" && window.location.pathname !== "/landing") {
      history.push("/");
    }
  };

  const toggleMobileMenu = (value: boolean) => {
    setMobileMenuOpened(value);
  };

  const confirmLogout = () => {
    setOpen(false);
    logout();
    history.push("/login");

    const cookies = new Cookies();
    cookies.remove("token");
  };

  const optionClick = (route: string, mobile: boolean) => {
    if (route === "/login") {
      setOpen(true);
    } else {
      history.push(route);
    }

    if (mobile) {
      toggleMobileMenu(false);
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <div className="header">
      <div className="header-content">
        <div className="left-side">
          <img src={logo} alt="logo" onClick={goHome} />
          {!isPublicPage ? <span>Alcancías Teletón</span> : null}
        </div>
        {!isPublicPage ? (
          <>
            <div className="header-options">
              {options.map((o, index) => {
                return (
                  <span className="option" key={index} onClick={() => optionClick(o.route, false)}>
                    {o.icon}
                    {o.name}
                  </span>
                );
              })}
            </div>
            <div className="mobile-header">
              <BurgerToggle mobileMenuOpened={mobileMenuOpened} toggleMobileMenu={toggleMobileMenu} />
              <Slide timeout={700} direction="up" in={mobileMenuOpened}>
                <div className="mobile-header-options">
                  {options.map((o, index) => {
                    return (
                      <span className="option" key={index} onClick={() => optionClick(o.route, true)}>
                        {o.icon}
                        {o.name}
                      </span>
                    );
                  })}
                </div>
              </Slide>
            </div>
            <Dialog className="logout" fullWidth maxWidth="xs" open={open} onClose={handleDialogClose}>
              <DialogTitle className="title">
                <CloseIcon className="blue-link" onClick={handleDialogClose} />
              </DialogTitle>
              <DialogContent className="content">
                <span>¿Estás seguro que deseas cerrar sesión?</span>
                <div className="actions">
                  <span className="blue-link" onClick={handleDialogClose}>
                    Cancelar
                  </span>
                  <Button className="confirm-button" color="secondary" variant="contained" onClick={confirmLogout}>
                    Cerrar sesión
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          </>
        ) : (
          <span>Bienvenidos a las alcancías Teletón</span>
        )}
      </div>
    </div>
  );
}

export default connector(withRouter(Header));
