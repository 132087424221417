import { get } from "./base.service";
import { endpoints } from "./endpoints";
import { SupportedIssuer } from "../store/config/types";

export const issuersService = {
  fetchIssuers,
};

async function fetchIssuers(): Promise<SupportedIssuer[] | undefined> {
  return await get<SupportedIssuer[]>(`${endpoints["issuers"]}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
