import { BASE_URL } from "../services/config";

export const urlHelper = {
  parseQueryString,
  isDetailedPage,
  isOnPublicUrl,
  isOnPrivateUrl,
  buildImageUrl,
};

function parseQueryString(queryString?: string): any {
  if (!queryString) {
    queryString = window.location.search.substring(1);
  }

  const params: any = {};

  const queries = queryString.split("&");

  queries.forEach((indexQuery: string) => {
    const indexPair = indexQuery.split("=");

    const queryKey = indexPair[0];
    const queryValue = indexPair.length > 1 ? indexPair[1] : "";

    params[queryKey] = queryValue;
  });

  return params;
}

const detailedPageUrls = [
  // "/details",
  //"/redirect",
  "/checkout",
  "/login",
  "/resend",
  "/reset",
  "/register",
  "/dashboard",
  "/confirm",
  "/moneyboxes",
  "/moneybox",
  "/newMoneybox",
  "/user",
  "/verify",
  "/faqs",
];

function isDetailedPage(url: string): boolean {
  if (url.startsWith("/mailSent")) {
    return true;
  }

  return detailedPageUrls.indexOf(url) > -1;
}

const publicUrls = [
  "/landing",
  "/loading",
  // "/details",
  "/redirect",
  "/checkout",
  "/result",
  "/login",
  "/resend",
  "/reset",
  "/confirm",
  "/register",
  "/verify",
  "/faqs",
  "/",
];

function isOnPublicUrl(url: string): boolean {
  if (url.startsWith("/mailSent")) {
    return true;
  }
  
  return publicUrls.indexOf(url) > -1;
}

const privateUrls = ["/dashboard", "/moneyboxes", "/user", "/moneybox", "/newMoneybox"];

function isOnPrivateUrl(url: string): boolean {
  return privateUrls.indexOf(url) > -1;
}

function buildImageUrl(id: string): string {
  return `${BASE_URL}/moneybox/${id}/picture`;
}
