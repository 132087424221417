import React, { useState } from "react";
import ManualLink from "./ManualLink";
import { Link, RootState, LinkRequest } from "../../../store/config/types";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "react-redux";
import { AppActions } from "../../../store/config/ActionTypes";
import { createLink } from "../../../store/action_creators/links.actions";
import { options } from "../../../data/options";
import { CustomSnackbar } from "../../common/CustomSnackbar";

const mapStateToProps = (state: RootState) => ({
  links: state.links,
  moneybox: state.moneybox,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  createLink: ({ currency, value, moneyboxId }: LinkRequest) => dispatch(createLink({ currency, value, moneyboxId })),
});

interface LinksCreationProps {
  addedPesosOptions: Link[];
  addedDollarsOptions: Link[];
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & LinksCreationProps;

function LinksCreation({ moneybox, links, createLink, addedPesosOptions, addedDollarsOptions }: PropsType) {
  const [open, setOpen] = useState<boolean>(false);
  const isAdded = (link: Link, list: Link[]): boolean => {
    return (
      list.filter((l) => {
        if (l.currency === link.currency && l.value === link.value) {
          return l;
        }
        return null;
      }).length > 0
    );
  };

  const notAddedPesosOptions = options.pesosOptions.filter((o) => (isAdded(o, addedPesosOptions) ? null : o));
  const notAddedDollarsOptions = options.dollarsOptions.filter((o) => (isAdded(o, addedDollarsOptions) ? null : o));

  const create = (link: Link) => {
    createLink({
      value: link.value,
      currency: link.currency,
      moneyboxId: moneybox.selectedMoneybox ? moneybox.selectedMoneybox?.id : "",
    });
    setOpen(true);
  };

  return (
    <div className="links-creation">
      <div className="quick-creation">
        <p>Creación rápida de links:</p>
        <div className="quick-link-row">
          <span className="currency">Pesos:</span>
          {notAddedPesosOptions.map((opt, index) => {
            return (
              <span key={index} className="quick-link" onClick={() => create(opt)}>
                <span className="blue-link">{opt.value}</span>
                {index === notAddedPesosOptions.length - 1 ? "" : ","}{" "}
              </span>
            );
          })}
        </div>
        <div className="quick-link-row">
          <span className="currency">Dólares:</span>
          {notAddedDollarsOptions.map((opt, index) => {
            return (
              <span key={index} className="quick-link" onClick={() => create(opt)}>
                <span className="green-link">{opt.value}</span>
                {index === notAddedDollarsOptions.length - 1 ? "" : ","}{" "}
              </span>
            );
          })}
        </div>
      </div>
      <ManualLink submitManualLink={create} />
      <CustomSnackbar
        open={open && links.createLinkErrorMessage !== null}
        message="Ocurrió un error al crear el link."
        handleClose={() => setOpen(false)}
        type={1}
      />
    </div>
  );
}

export default connector(LinksCreation);
