import React from "react";
import { options } from "../../../data/options";
import { currencyHelper } from "../../../helpers/currencyHelper";
import { numberHelper } from "../../../helpers/numberHelper";
import { Currency } from "../../../store/config/enums";
import { Donation, MoneyboxState } from "../../../store/config/types";

interface DonateOptionProps {
  moneybox: MoneyboxState;
  currency: Currency;
  option: number;
  advanceToDetails({ moneyboxId, currency, value }: Donation): void;
}

function DonateOption({ moneybox, currency, option, advanceToDetails }: DonateOptionProps) {
  return (
    <div
      className="option"
      onClick={() =>
        advanceToDetails({
          moneyboxId: moneybox.validatedMoneybox ? moneybox.validatedMoneybox.id : options.anonMoneybox,
          currency,
          value: Number(option),
        })
      }
    >
      <div className={`option-content ${currency === Currency.DOLAR ? "dollar-option-content" : ""}`}>
        <span className="donate">Donar</span>
        <p>
          {currencyHelper.getCurrencyToken(currency)}
          <span className="amount">{numberHelper.getNumberWithDots(option)}</span>
        </p>
      </div>
    </div>
  );
}

export default DonateOption;
