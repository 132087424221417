import React, { useEffect, useRef, useState } from "react";
import { Button } from "@material-ui/core";
import logo from "../../../assets/logo.png";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { withRouter, RouteComponentProps } from "react-router-dom";

function RedLanding({ history }: RouteComponentProps) {
  const theRef = useRef(null);
  const [image, setImage] = useState<string>("unset");

  const createMoneybox = () => {
    history.push("/register");
  };

  const loginToMoneybox = () => {
    history.push("/login");
  };

  const scrollToDonate = () => {
    let element: HTMLElement = theRef.current!;

    window.scrollTo({ behavior: "smooth", top: element.offsetHeight + 160 });
  };

  const goToFaqs = () => {
    history.push("/faqs");
  };

  const randomizeImage = () => {
    const random = Math.floor(Math.random() * 10) % 5;
    setImage(`background-${random + 1}`);
  };

  useEffect(() => {
    if (image === "unset") {
      randomizeImage();
    }
  });

  return (
    <div className={`red-landing ${image}`} ref={theRef}>
      <div className="content">
        <img src={logo} alt="logo" />
        <p className="title">Bienvenidos a las alcancías Teletón</p>
        <p className="speech">
          Las Alcancías Teletón están hechas para que grupos u organizaciones puedan brindar a sus integrantes una forma
          sencilla de donar.
        </p>
        <Button classes={{ root: "multiline-button" }} onClick={createMoneybox} disableElevation>
          <div className="button-content">
            <span className="big">Crear una alcancía</span>
            <span className="small">para tu grupo u organización</span>
          </div>
        </Button>
        <Button classes={{ root: "transparent-button" }} onClick={loginToMoneybox} disableElevation>
          ¿Ya tenés una alcancía Teletón? Ingresá aquí
        </Button>
      </div>
      <div className="white-box">
        <div className="questions">
          <span>¿Cómo funcionan las alcancías Teletón?</span>
          <span>¿Qué se entiende como grupo u organización?</span>
          <span className="blue-link" onClick={goToFaqs}>
            Encontrá las respuestas en nuestras preguntas frecuentes
          </span>
        </div>
        <div className="separator" />
        <div className="blue-link" onClick={scrollToDonate}>
          <h2>¿Tan solo querés donar?</h2>
          <p>Podés hacerlo de forma 100% online aquí abajo.</p>
          <KeyboardArrowDownIcon fontSize="large" />
        </div>
      </div>
    </div>
  );
}

export default withRouter(RedLanding);
