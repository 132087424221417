import React from "react";
import { Dialog, DialogContent, Button, makeStyles, DialogTitle } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useDialogStyles = makeStyles({
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingBottom: 0,
  },
  content: {
    padding: 0,
    paddingBottom: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  actions: {
    marginTop: 24,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    marginLeft: 16,
  },
});

interface DeleteDialogProps {
  open: boolean;
  setOpen(value: boolean): void;
  message: string;
  deleteAction(): void;
}

function DeleteDialog({ open, setOpen, message, deleteAction }: DeleteDialogProps) {
  const classes = useDialogStyles();

  const handleDelete = () => {
    deleteAction();
    setOpen(false);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <Dialog className="dialog" fullWidth maxWidth="xs" open={open} onClose={handleDialogClose}>
      <DialogTitle className={classes.title}>
        <CloseIcon className="blue-link" onClick={handleDialogClose} />
      </DialogTitle>
      <DialogContent className={classes.content}>
        {message}
        <div className={classes.actions}>
          <span className="blue-link" onClick={handleDialogClose}>
            No, cancelar
          </span>
          <Button className={classes.button} color="secondary" variant="contained" onClick={handleDelete}>
            Si, eliminar
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default DeleteDialog;
