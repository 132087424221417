import { LinksState } from "../config/types";
import {
  LinksDataTypes,
  LOADING_LINKS,
  SHARE_LINK_FAILED,
  SHARE_LINK_SUCCESS,
  SHARING_LINK,
  DELETE_LINK_FAILED,
  DELETE_LINK_SUCCESS,
  DELETING_LINK,
  CREATE_LINK_FAILED,
  CREATE_LINK_SUCCESS,
  CREATING_LINK,
  LINKS_FAILED,
  ADD_LINKS,
  LogoutActionTypes,
  LOGOUT,
} from "../config/ActionTypes";

export const initialState: LinksState = {
  loadingLinks: false,
  links: null,
  linksErrorMessage: null,
  creatingLink: false,
  createLinkSuccess: false,
  createLinkErrorMessage: null,
  deletingLink: false,
  deleteLinkSuccess: false,
  deleteLinkErrorMessage: null,
  sharingLink: false,
  shareLinkSuccess: false,
  shareLinkErrorMessage: null,
};

export function linksReducer(state = initialState, action: LinksDataTypes | LogoutActionTypes): LinksState {
  switch (action.type) {
    case LOADING_LINKS:
      return {
        ...state,
        loadingLinks: true,
        links: null,
        linksErrorMessage: null,
      };

    case ADD_LINKS:
      return {
        ...state,
        loadingLinks: false,
        links: action.links,
        linksErrorMessage: null,
      };

    case LINKS_FAILED:
      return {
        ...state,
        loadingLinks: false,
        links: null,
        linksErrorMessage: action.error,
      };

    case CREATING_LINK:
      return {
        ...state,
        creatingLink: true,
        createLinkSuccess: false,
        createLinkErrorMessage: null,
      };

    case CREATE_LINK_SUCCESS:
      const newLinks = state.links ? [...state.links] : [];
      newLinks.push(action.link);
      return {
        ...state,
        links: newLinks,
        creatingLink: false,
        createLinkSuccess: true,
        createLinkErrorMessage: null,
      };

    case CREATE_LINK_FAILED:
      return {
        ...state,
        creatingLink: false,
        createLinkSuccess: false,
        createLinkErrorMessage: action.error,
      };

    case DELETING_LINK:
      return {
        ...state,
        deletingLink: true,
        deleteLinkSuccess: false,
        deleteLinkErrorMessage: null,
      };

    case DELETE_LINK_SUCCESS:
      return {
        ...state,
        deletingLink: false,
        deleteLinkSuccess: true,
        deleteLinkErrorMessage: null,
      };

    case DELETE_LINK_FAILED:
      return {
        ...state,
        deletingLink: false,
        deleteLinkSuccess: false,
        deleteLinkErrorMessage: action.error,
      };

    case SHARING_LINK:
      return {
        ...state,
        sharingLink: true,
        shareLinkSuccess: false,
        shareLinkErrorMessage: null,
      };

    case SHARE_LINK_SUCCESS:
      return {
        ...state,
        sharingLink: false,
        shareLinkSuccess: true,
        shareLinkErrorMessage: null,
      };

    case SHARE_LINK_FAILED:
      return {
        ...state,
        sharingLink: false,
        shareLinkSuccess: false,
        shareLinkErrorMessage: action.error,
      };

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
