import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import { FormTextField } from '../../components/forms/FormTextField'
import schemas from '../../data/schemas'
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom'
import { RootState, ResendEmailRequest } from '../../store/config/types'
import { ThunkDispatch } from 'redux-thunk'
import { AppActions } from '../../store/config/ActionTypes'
import { resendEmail } from '../../store/action_creators/auth.actions'
import { connect, ConnectedProps } from 'react-redux'
import { CustomSnackbar } from '../../components/common/CustomSnackbar'
import Cookies from 'universal-cookie'

interface Values {
  email: string
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  resendEmail: ({ email }: ResendEmailRequest) => dispatch(resendEmail({ email })),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>
type PropsType = PropsFromRedux & RouteComponentProps<any>

function Resend({ auth, resendEmail, history }: PropsType) {
  const [resending, setResending] = useState(false)

  const cookies = new Cookies()
  const hasToken = cookies.get('token') !== undefined

  const goBack = () => {
    history.push('/login')
  }

  const submit = (values: Values) => {
    setResending(true)

    resendEmail({
      email: values.email,
    })
  }

  const closeSnack = () => {
    setResending(false)
  }

  return !hasToken ? (
    <div className="reset-password">
      <div className="title">
        <div className="left">
          <h2>Reenviar mail de confirmación</h2>
          <p>
            Ingresá el mail con el cual te registraste y te reenviaremos el link de verificación.
          </p>
        </div>
        <span className="blue-link" onClick={goBack}>
          Volver
        </span>
      </div>
      <Formik
        initialValues={{ email: auth.loginEmail ? auth.loginEmail : '' }}
        validationSchema={schemas.ResetPasswordSchema}
        onSubmit={submit}
      >
        <Form className="form reset-password-form">
          <Field name="email" component={FormTextField} type="text" placeholder="Email" />
          <div className="actions-row">
            <div />
            <Button color="secondary" variant="contained" type="submit">
              Reenviar mail
            </Button>
          </div>
        </Form>
      </Formik>
      <CustomSnackbar
        open={resending && (auth.resendEmailSuccess || auth.resendEmailErrorMessage !== null)}
        message={
          auth.resendEmailSuccess
            ? 'Se reenvió el mail de verificación'
            : 'Ocurrió un error al enviar el mail de verificación'
        }
        handleClose={closeSnack}
        type={auth.resendEmailSuccess ? 0 : 1}
      />
    </div>
  ) : (
    <Redirect to="/dashboard" />
  )
}

export default connector(withRouter(Resend))
