import React, { useEffect, useMemo, useState } from "react";
import QRPdf from "./QRPdf";
import QRCode from "qrcode.react";
import { Document, PDFDownloadLink } from "@react-pdf/renderer";
import { Link, Moneybox } from "../../../store/config/types";
import { Currency } from "../../../store/config/enums";

interface DownloadQRProps {
  text?: string;
  selectedMoneybox: Moneybox;
  selectedLink?: Link;
}

function DownloadQR({ text, selectedMoneybox, selectedLink }: DownloadQRProps) {
  const url = selectedLink
    ? `${window.location.origin}/landing?code=${selectedLink?.code}`
    : `${window.location.origin}/${selectedMoneybox.fantasyName}`;
  const [qr, setQr] = useState<string | undefined | null>(null);
  const [canvasUrl, setCanvasUrl] = useState<string>("")

  useEffect(() => {
    if (qr === null || url !== canvasUrl) {
      setCanvasUrl(url)
      const qrCodeCanvas: any = document.querySelector(
        `#${selectedLink ? `id${selectedLink?.code}` : `id${selectedMoneybox.fantasyName}`} canvas`
      );
      const qrCodeDataUri = qrCodeCanvas?.toDataURL("image/jpg", 0.3);
      setQr(qrCodeDataUri);
    }
  }, [qr, setQr, selectedLink, selectedMoneybox.fantasyName, canvasUrl, setCanvasUrl, url]);

  const DownloadPdf = () => {
    return useMemo(
      () => (
        <PDFDownloadLink
          document={
            <Document>
              <QRPdf qrString={qr} selectedMoneybox={selectedMoneybox} selectedLink={selectedLink} />
            </Document>
          }
          fileName={`${selectedMoneybox.name}_${selectedLink?.currency === 1 ? "UYU" : "USD"}${selectedLink?.value}_QR`}
          style={{ textDecoration: "none" }}
        >
          {({ loading }) =>
            loading ? (
              "Cargando..."
            ) : (
              <span className={`${selectedLink?.currency === Currency.DOLAR ? "green-link" : "blue-link"}`}>
                {text ? text : "Descargar QR"}
              </span>
            )
          }
        </PDFDownloadLink>
      ),
      []
    );
  };

  return (
    <div id={selectedLink ? `id${selectedLink?.code}` : `id${selectedMoneybox.fantasyName}`} className="qr">
      <QRCode value={url} renderAs="canvas" style={{ display: "none" }} />
      <DownloadPdf />
    </div>
  );
}

export default DownloadQR;
