import { Dispatch } from "redux";
import { SupportedIssuer } from "../config/types";
import {
  LOADING_ISSUERS,
  ADD_ISSUERS,
  ISSUERS_FAILED,
  FetchIssuersTypes,
} from "../config/ActionTypes";
import { issuersService } from "../../services/issuers";

//#region Fetch links

export const fetchIssuers = () => {
  return (dispatch: Dispatch<FetchIssuersTypes>) => {
    dispatch(loadingIssuers());

    return issuersService.fetchIssuers().then(
      (response) => {
        dispatch(addIssuers(response!));
      },
      (error) => {
        dispatch(issuersFailed(error));
      }
    );
  };
};

export const loadingIssuers = (): FetchIssuersTypes => ({
  type: LOADING_ISSUERS,
});

export const addIssuers = (issuers: SupportedIssuer[]): FetchIssuersTypes => ({
  type: ADD_ISSUERS,
  issuers,
});

export const issuersFailed = (error: string): FetchIssuersTypes => ({
  type: ISSUERS_FAILED,
  error,
});

//#endregion
