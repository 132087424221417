import React from 'react'
import WarningIcon from "@material-ui/icons/Warning";
import blackLogo from "../../assets/blackLogo.png";
import { useParams } from 'react-router';

interface MailParams {
    mail: string;
}

function MailSent() {
    const { mail } = useParams<MailParams>();

    return (
        <div className="mail-sent">
            <img src={blackLogo} alt="logo" />
            <p className="email-sent">
                Te enviamos un email de verificación a <span className="email">{mail}</span>
            </p>
            <span className="email-sent-speech">Allí encontrarás las instrucciones para ingresar a tu cuenta.</span>
            <div className="email-sent-warning">
                <WarningIcon color="primary" />
                <p>Si no recibiste este correo después de unos minutos, revisa tu casilla de spam.</p>
            </div>
        </div>
    )
}

export default (MailSent)
