import {
  User,
  Donation,
  Moneybox,
  Link,
  ConfirmDonationResponse,
  Totals,
  DonationResponse,
  SupportedIssuer,
  MoneyboxTotalsResponse,
} from './types';

//#region Auth actions

export const LOGGING_IN = 'LOGGING_IN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export interface LoggingInAction {
  type: typeof LOGGING_IN;
  email: string;
}

export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
}

export interface LoginErrorAction {
  type: typeof LOGIN_ERROR;
  error: string;
}

export type LoginActionTypes = LoggingInAction | LoginSuccessAction | LoginErrorAction;

export const LOGOUT = 'LOGOUT';

export interface LogoutAction {
  type: typeof LOGOUT;
}

export type LogoutActionTypes = LogoutAction;

export const REGISTERING = 'REGISTERING';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';

export interface RegisteringAction {
  type: typeof REGISTERING;
}

export interface RegisterSuccessAction {
  type: typeof REGISTER_SUCCESS;
  user: User;
}

export interface RegisterErrorAction {
  type: typeof REGISTER_ERROR;
  error: string;
}

export type RegisterActionTypes = RegisteringAction | RegisterSuccessAction | RegisterErrorAction;

export const CHECKING_EMAIL = 'CHECKING_EMAIL';
export const CHECK_EMAIL_SUCCESS = 'CHECK_EMAIL_SUCCESS';
export const CHECK_EMAIL_ERROR = 'CHECK_EMAIL_ERROR';

export interface CheckingEmailAction {
  type: typeof CHECKING_EMAIL;
}

export interface CheckEmailSuccessAction {
  type: typeof CHECK_EMAIL_SUCCESS;
}

export interface CheckEmailErrorAction {
  type: typeof CHECK_EMAIL_ERROR;
  error: string;
}

export type CheckEmailActionTypes = CheckingEmailAction | CheckEmailSuccessAction | CheckEmailErrorAction;

export const PASSWORD_CHANGING = 'PASSWORD_CHANGING';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';
export const PASSWORD_CHANGE_ERROR = 'PASSWORD_CHANGE_ERROR';

export interface PasswordChanging {
  type: typeof PASSWORD_CHANGING;
}
export interface PasswordChangedSuccess {
  type: typeof PASSWORD_CHANGE_SUCCESS;
}
export interface PasswordChangedError {
  type: typeof PASSWORD_CHANGE_ERROR;
  error: string;
}

export type PasswordChangeTypes = PasswordChanging | PasswordChangedSuccess | PasswordChangedError;

export const RESETING_PASSWORD = 'RESETING_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export interface ResetingPassword {
  type: typeof RESETING_PASSWORD;
}
export interface ResetPasswordSuccess {
  type: typeof RESET_PASSWORD_SUCCESS;
}
export interface ResetPasswordError {
  type: typeof RESET_PASSWORD_ERROR;
  error: string;
}

export type ResetPasswordTypes = ResetingPassword | ResetPasswordSuccess | ResetPasswordError;

export const CONFIRMING_RESET_PASSWORD = 'CONFIRMING_RESET_PASSWORD';
export const CONFIRM_RESET_PASSWORD_SUCCESS = 'CONFIRM_RESET_PASSWORD_SUCCESS';
export const CONFIRM_RESET_PASSWORD_ERROR = 'CONFIRM_RESET_PASSWORD_ERROR';

export interface ConfirmingResetPassword {
  type: typeof CONFIRMING_RESET_PASSWORD;
}
export interface ConfirmResetPasswordSuccess {
  type: typeof CONFIRM_RESET_PASSWORD_SUCCESS;
  recoveredPassword: string;
}
export interface ConfirmResetPasswordError {
  type: typeof CONFIRM_RESET_PASSWORD_ERROR;
  error: string;
}

export type ConfirmResetPasswordTypes =
  | ConfirmingResetPassword
  | ConfirmResetPasswordSuccess
  | ConfirmResetPasswordError;

export const CONFIRMING_EMAIL = 'CONFIRMING_EMAIL';
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS';
export const CONFIRM_EMAIL_ERROR = 'CONFIRM_EMAIL_ERROR';

export interface ConfirmingEmail {
  type: typeof CONFIRMING_EMAIL;
}

export interface ConfirmEmailSuccess {
  type: typeof CONFIRM_EMAIL_SUCCESS;
}

export interface ConfirmEmailError {
  type: typeof CONFIRM_EMAIL_ERROR;
  error: string;
}

export type ConfirmEmailTypes = ConfirmingEmail | ConfirmEmailSuccess | ConfirmEmailError;

export const RESENDING_EMAIL = 'RESENDING_EMAIL';
export const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS';
export const RESEND_EMAIL_ERROR = 'RESEND_EMAIL_ERROR';

export interface ResendingEmail {
  type: typeof RESENDING_EMAIL;
}

export interface ResendEmailSuccess {
  type: typeof RESEND_EMAIL_SUCCESS;
}

export interface ResendEmailError {
  type: typeof RESEND_EMAIL_ERROR;
  error: string;
}

export type ResendEmailTypes = ResendingEmail | ResendEmailSuccess | ResendEmailError;

export type AuthDataTypes =
  | LoginActionTypes
  | LogoutActionTypes
  | RegisterActionTypes
  | CheckEmailActionTypes
  | PasswordChangeTypes
  | ResetPasswordTypes
  | ConfirmResetPasswordTypes
  | ConfirmEmailTypes
  | ResendEmailTypes;

//#endregion

//#region Donation actions

export const LOAD_LANDING = 'LOAD_LANDING';

export interface LoadLanding {
  type: typeof LOAD_LANDING;
  value: boolean;
}

export const SET_DONATION = 'SET_DONATION';

export interface SetDonation {
  type: typeof SET_DONATION;
  donation: Donation;
}

export const DONATION_CONFIRMING = 'DONATION_CONFIRMING';
export const DONATION_CONFIRM_SUCCESS = 'DONATION_CONFIRM_SUCCESS';
export const DONATION_CONFIRM_ERROR = 'DONATION_CONFIRM_ERROR';

export interface DonationConfirming {
  type: typeof DONATION_CONFIRMING;
}
export interface DonationConfirmSuccess {
  type: typeof DONATION_CONFIRM_SUCCESS;
  confirmDonationResponse: ConfirmDonationResponse;
}
export interface DonationConfirmError {
  type: typeof DONATION_CONFIRM_ERROR;
  error: string;
}

export type DonationConfirmTypes = DonationConfirming | DonationConfirmSuccess | DonationConfirmError;

export const DONATION_FETCHING = 'DONATION_FETCHING';
export const DONATION_FETCH_SUCCESS = 'DONATION_FETCH_SUCCESS';
export const DONATION_FETCH_ERROR = 'DONATION_FETCH_ERROR';

export interface DonationFetching {
  type: typeof DONATION_FETCHING;
}
export interface DonationFetchSuccess {
  type: typeof DONATION_FETCH_SUCCESS;
  donationResponse: DonationResponse;
}
export interface DonationFetchError {
  type: typeof DONATION_FETCH_ERROR;
  error: string;
}

export type DonationFetchTypes = DonationFetching | DonationFetchSuccess | DonationFetchError;

export const CLEAR_INSTRUMENT = 'CLEAR_INSTRUMENT';

export interface ClearInstrument {
  type: typeof CLEAR_INSTRUMENT;
}

export type ClearInstrumentTypes = ClearInstrument;

export const DONATION_FINALIZING = 'DONATION_FINALIZING';
export const DONATION_FINALIZE_SUCCESS = 'DONATION_FINALIZE_SUCCESS';
export const DONATION_FINALIZE_ERROR = 'DONATION_FINALIZE_ERROR';

export interface DonationFinalizing {
  type: typeof DONATION_FINALIZING;
}
export interface DonationFinalizeSuccess {
  type: typeof DONATION_FINALIZE_SUCCESS;
  totals: Totals;
}
export interface DonationFinalizeError {
  type: typeof DONATION_FINALIZE_ERROR;
  error: string;
}

export type DonationFinalizeTypes = DonationFinalizing | DonationFinalizeSuccess | DonationFinalizeError;

export type DonationDataTypes =
  | LoadLanding
  | SetDonation
  | DonationConfirmTypes
  | DonationFetchTypes
  | ClearInstrumentTypes
  | DonationFinalizeTypes;

//#endregion

//#region Moneybox actions

export const LOADING_MONEYBOXES = 'LOADING_MONEYBOXES';
export const ADD_MONEYBOXES = 'ADD_MONEYBOXES';
export const MONEYBOXES_FAILED = 'MONEYBOXES_FAILED';

export interface LoadingMoneyboxesAction {
  type: typeof LOADING_MONEYBOXES;
}

export interface AddMoneyboxesAction {
  type: typeof ADD_MONEYBOXES;
  moneyboxes: Moneybox[];
}

export interface MoneyboxesFailedAction {
  type: typeof MONEYBOXES_FAILED;
  error: string;
}

export type FetchMoneyboxesTypes = LoadingMoneyboxesAction | AddMoneyboxesAction | MoneyboxesFailedAction;

export const LOADING_MONEYBOX = 'LOADING_MONEYBOX';
export const ADD_MONEYBOX = 'ADD_MONEYBOX';
export const MONEYBOX_FAILED = 'MONEYBOX_FAILED';

export interface LoadingMoneyboxAction {
  type: typeof LOADING_MONEYBOX;
}

export interface AddMoneyboxAction {
  type: typeof ADD_MONEYBOX;
  moneybox: Moneybox;
}

export interface MoneyboxFailedAction {
  type: typeof MONEYBOX_FAILED;
  error: string;
}

export type GetMoneyboxTypes = LoadingMoneyboxAction | AddMoneyboxAction | MoneyboxFailedAction;

export const SELECT_MONEYBOX = 'SELECT_MONEYBOX';

export interface SelectMoneyboxAction {
  type: typeof SELECT_MONEYBOX;
  moneybox: Moneybox;
}

export type SelectMoneyboxTypes = SelectMoneyboxAction;

export const CREATING_MONEYBOX = 'CREATING_MONEYBOX';
export const CREATE_MONEYBOX_SUCCESS = 'CREATE_MONEYBOX_SUCCESS';
export const CREATE_MONEYBOX_FAILED = 'CREATE_MONEYBOX_FAILED';
export const CREATE_MONEYBOX_FINISH = 'CREATE_MONEYBOX_FINISH';

export interface CreatingMoneyboxAction {
  type: typeof CREATING_MONEYBOX;
}

export interface CreateMoneyboxSuccessAction {
  type: typeof CREATE_MONEYBOX_SUCCESS;
  moneybox: Moneybox;
}

export interface CreateMoneyboxFailedAction {
  type: typeof CREATE_MONEYBOX_FAILED;
  error: string;
  failedMoneyboxName: string;
  failedMoneyboxFantasyName: string;
}

export interface CreateMoneyboxFinishAction {
  type: typeof CREATE_MONEYBOX_FINISH;
}

export type CreateMoneyboxTypes =
  | CreatingMoneyboxAction
  | CreateMoneyboxSuccessAction
  | CreateMoneyboxFailedAction
  | CreateMoneyboxFinishAction;

export const UPDATING_MONEYBOX = 'UPDATING_MONEYBOX';
export const UPDATE_MONEYBOX_SUCCESS = 'UPDATE_MONEYBOX_SUCCESS';
export const UPDATE_MONEYBOX_FAILED = 'UPDATE_MONEYBOX_FAILED';

export interface UpdatingMoneyboxAction {
  type: typeof UPDATING_MONEYBOX;
}

export interface UpdateMoneyboxSuccessAction {
  type: typeof UPDATE_MONEYBOX_SUCCESS;
  moneybox: Moneybox;
}

export interface UpdateMoneyboxFailedAction {
  type: typeof UPDATE_MONEYBOX_FAILED;
  error: string;
}

export type UpdateMoneyboxTypes =
  | UpdatingMoneyboxAction
  | UpdateMoneyboxSuccessAction
  | UpdateMoneyboxFailedAction;

export const DELETING_MONEYBOX = 'DELETING_MONEYBOX';
export const DELETE_MONEYBOX_SUCCESS = 'DELETE_MONEYBOX_SUCCESS';
export const DELETE_MONEYBOX_FAILED = 'DELETE_MONEYBOX_FAILED';

export interface DeletingMoneyboxAction {
  type: typeof DELETING_MONEYBOX;
}

export interface DeleteMoneyboxSuccessAction {
  type: typeof DELETE_MONEYBOX_SUCCESS;
  moneyboxId: string;
}

export interface DeleteMoneyboxFailedAction {
  type: typeof DELETE_MONEYBOX_FAILED;
  error: string;
}

export type DeleteMoneyboxTypes =
  | DeletingMoneyboxAction
  | DeleteMoneyboxSuccessAction
  | DeleteMoneyboxFailedAction;

export const VALIDATING_MONEYBOX = 'VALIDATING_MONEYBOX';
export const VALIDATE_MONEYBOX_SUCCESS = 'VALIDATE_MONEYBOX_SUCCESS';
export const VALIDATE_MONEYBOX_FAILED = 'VALIDATE_MONEYBOX_FAILED';
export const CLEAR_MONEYBOX = 'CLEAR_MONEYBOX';

export interface ValidatingMoneyboxAction {
  type: typeof VALIDATING_MONEYBOX;
}

export interface ValidateMoneyboxSuccessAction {
  type: typeof VALIDATE_MONEYBOX_SUCCESS;
  moneybox: Moneybox;
  link: Link;
}

export interface ValidateMoneyboxFailedAction {
  type: typeof VALIDATE_MONEYBOX_FAILED;
  error: string;
}

export interface ClearMoneyboxAction {
  type: typeof CLEAR_MONEYBOX;
}

export type ValidateMoneyboxTypes =
  | ValidatingMoneyboxAction
  | ValidateMoneyboxSuccessAction
  | ValidateMoneyboxFailedAction
  | ClearMoneyboxAction;

export const MONEYBOXES_FETCHING_TOTALS = 'MONEYBOXES_FETCHING_TOTALS';
export const MONEYBOXES_FETCH_TOTALS_SUCCESS = 'MONEYBOXES_FETCH_TOTALS_SUCCESS';
export const MONEYBOXES_FETCH_TOTALS_ERROR = 'MONEYBOXES_FETCH_TOTALS_ERROR';

export interface MoneyboxesFetchingTotals {
  type: typeof MONEYBOXES_FETCHING_TOTALS;
}

export interface MoneyboxesFetchTotalsSuccess {
  type: typeof MONEYBOXES_FETCH_TOTALS_SUCCESS;
  response: MoneyboxTotalsResponse;
}

export interface MoneyboxesFetchTotalsError {
  type: typeof MONEYBOXES_FETCH_TOTALS_ERROR;
  error: string;
}

export type MoneyboxesFetchTotalsTypes =
  | MoneyboxesFetchingTotals
  | MoneyboxesFetchTotalsSuccess
  | MoneyboxesFetchTotalsError;

export type MoneyboxDataTypes =
  | FetchMoneyboxesTypes
  | GetMoneyboxTypes
  | SelectMoneyboxTypes
  | CreateMoneyboxTypes
  | UpdateMoneyboxTypes
  | DeleteMoneyboxTypes
  | ValidateMoneyboxTypes
  | MoneyboxesFetchTotalsTypes;

//#endregion

//#region Links actions

export const LOADING_LINKS = 'LOADING_LINKS';
export const ADD_LINKS = 'ADD_LINKS';
export const LINKS_FAILED = 'LINKS_FAILED';

export interface LoadingLinksAction {
  type: typeof LOADING_LINKS;
}

export interface AddLinksAction {
  type: typeof ADD_LINKS;
  links: Link[];
}

export interface LinksFailedAction {
  type: typeof LINKS_FAILED;
  error: string;
}

export type FetchLinksTypes = LoadingLinksAction | AddLinksAction | LinksFailedAction;

export const CREATING_LINK = 'CREATING_LINK';
export const CREATE_LINK_SUCCESS = 'CREATE_LINK_SUCCESS';
export const CREATE_LINK_FAILED = 'CREATE_LINK_FAILED';

export interface CreatingLinkAction {
  type: typeof CREATING_LINK;
}

export interface CreateLinkSuccessAction {
  type: typeof CREATE_LINK_SUCCESS;
  link: Link;
}

export interface CreateLinkFailedAction {
  type: typeof CREATE_LINK_FAILED;
  error: string;
}

export type CreateLinkTypes = CreatingLinkAction | CreateLinkSuccessAction | CreateLinkFailedAction;

export const DELETING_LINK = 'DELETING_LINK';
export const DELETE_LINK_SUCCESS = 'DELETE_LINK_SUCCESS';
export const DELETE_LINK_FAILED = 'DELETE_LINK_FAILED';

export interface DeletingLinkAction {
  type: typeof DELETING_LINK;
}

export interface DeleteLinkSuccessAction {
  type: typeof DELETE_LINK_SUCCESS;
  linkId: string;
}

export interface DeleteLinkFailedAction {
  type: typeof DELETE_LINK_FAILED;
  error: string;
}

export type DeleteLinkTypes = DeletingLinkAction | DeleteLinkSuccessAction | DeleteLinkFailedAction;

export const SHARING_LINK = 'SHARING_LINK';
export const SHARE_LINK_SUCCESS = 'SHARE_LINK_SUCCESS';
export const SHARE_LINK_FAILED = 'SHARE_LINK_FAILED';

export interface SharingLinkAction {
  type: typeof SHARING_LINK;
}

export interface ShareLinkSuccessAction {
  type: typeof SHARE_LINK_SUCCESS;
}

export interface ShareLinkFailedAction {
  type: typeof SHARE_LINK_FAILED;
  error: string;
}

export type ShareLinkTypes = SharingLinkAction | ShareLinkSuccessAction | ShareLinkFailedAction;

export type LinksDataTypes = FetchLinksTypes | CreateLinkTypes | DeleteLinkTypes | ShareLinkTypes;

//#endregion

//#region Issuers

export const LOADING_ISSUERS = 'LOADING_ISSUERS';
export const ADD_ISSUERS = 'ADD_ISSUERS';
export const ISSUERS_FAILED = 'ISSUERS_FAILED';

export interface LoadingIssuersAction {
  type: typeof LOADING_ISSUERS;
}

export interface AddIssuersAction {
  type: typeof ADD_ISSUERS;
  issuers: SupportedIssuer[];
}

export interface IssuersFailedAction {
  type: typeof ISSUERS_FAILED;
  error: string;
}

export type FetchIssuersTypes = LoadingIssuersAction | AddIssuersAction | IssuersFailedAction;

export type IssuersDataTypes = FetchIssuersTypes;

//#endregion

export type AppActions = AuthDataTypes | DonationDataTypes | MoneyboxDataTypes | IssuersDataTypes;
