import { Paper } from "@material-ui/core";
import React from "react";
import logo from "../../assets/logo.png";
import MoodBadIcon from "@material-ui/icons/MoodBad";
import { RouteComponentProps, withRouter } from "react-router-dom";

function ErrorPage({ history }: RouteComponentProps<any>) {
  const goToLanding = () => {
    let code = localStorage.getItem("code");

    if (code === "none") {
      history.push("/landing");
    } else {
      history.push(`/landing?code=${code}`);
    }
  };

  return (
    <div className="result">
      <div className="result-content">
        <div className="result-header">
          <img src={logo} alt="Logo de la Teletón" />
        </div>

        <div className="result-content result-content-error">
          <Paper className="result-box">
            <div className="result-box-header">
              <MoodBadIcon />
              <div className="result-box-header-text">
                <h2>Hubo un problema</h2>
                <p className="result-error">Error al agregar medio de pago.</p>
              </div>
            </div>
            <p>No se pudo agregar tu medio de pago para que completes la donación. Vuelve a intentarlo más tarde.</p>
            <hr />
            <div className="result-box-actions">
              <span className="blue-link" onClick={goToLanding} style={{ margin: "auto" }}>
                Volver al inicio
              </span>
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ErrorPage);
