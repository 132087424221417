import React, { useState, useRef } from "react";
import { Formik, Form, Field, FormikHelpers } from "formik";
import { FormTextField } from "../FormTextField";
import { Button, CircularProgress, TextField } from "@material-ui/core";
import {
  CreateMoneyboxRequest,
  RootState,
  Moneybox,
  UpdateMoneyboxRequest,
  UpdateImageRequest,
} from "../../../store/config/types";
import {
  createMoneybox,
  updateMoneybox,
  finishMoneyboxCreation,
} from "../../../store/action_creators/moneybox.actions";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../store/config/ActionTypes";
import { connect, ConnectedProps } from "react-redux";
import { moneyboxService } from "../../../services/moneybox.service";
import { CustomSnackbar } from "../../common/CustomSnackbar";
import { withRouter, RouteComponentProps } from "react-router-dom";
import schemas from "../../../data/schemas";
import { urlHelper } from "../../../helpers/urlHelper";

interface Values {
  name: string;
  fantasyName: string;
}

interface MoneyboxFormProps {
  selectedMoneybox?: Moneybox;
}

const mapStateToProps = (state: RootState) => ({
  moneybox: state.moneybox,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  createMoneybox: ({ fantasyName, name, file }: CreateMoneyboxRequest) =>
    dispatch(createMoneybox({ fantasyName, name, file })),
  updateMoneybox: ({ moneyboxId, fantasyName }: UpdateMoneyboxRequest) =>
    dispatch(updateMoneybox({ moneyboxId, fantasyName })),
  finishMoneyboxCreation: () => dispatch(finishMoneyboxCreation()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & MoneyboxFormProps & RouteComponentProps<any>;

function MoneyboxForm({
  moneybox,
  createMoneybox,
  finishMoneyboxCreation,
  updateMoneybox,
  selectedMoneybox,
  history,
}: PropsType) {
  const [updating, setUpdating] = useState<boolean>(false);
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const [selectedPhoto, setSelectedPhoto] = useState<File | null>(null);
  const [pictureError, setPictureError] = useState<string | null>(null);

  const photoInput = useRef<HTMLInputElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const submitMoneybox = (values: Values, helpers: FormikHelpers<Values>) => {
    if (selectedMoneybox) {
      updateMoneybox({
        moneyboxId: selectedMoneybox!.id,
        fantasyName: values.fantasyName,
      });
      setUpdating(true);

      if (selectedPhoto) {
        const updateImageRequest: UpdateImageRequest = {
          moneyboxId: selectedMoneybox!.id,
          file: selectedPhoto!,
        };

        moneyboxService.updateMoneyboxPicture(updateImageRequest).catch((error: Error) => {
          setPictureError(error.message);
        });
      }
    } else {
      createMoneybox({
        fantasyName: values.fantasyName,
        name: values.name,
        file: selectedPhoto!,
      });
    }
  };

  const onPhotoSelected = (files: FileList | null) => {
    setLoadingImage(false);
    setSelectedPhoto(files ? files[0] : null);

    if (selectedMoneybox !== null) {
      if (FileReader && files && files.length) {
        var fr = new FileReader();
        fr.onload = function () {
          if (imageRef.current) {
            imageRef.current.src = fr.result!.toString();
          }
        };
        fr.readAsDataURL(files[0]);
      }
    }
  };

  const closeSnack = () => {
    setUpdating(false);

    if (moneybox.createMoneyboxSuccess) {
      history.push("/moneyboxes");
      finishMoneyboxCreation();
    }

    if (moneybox.updateMoneyboxSuccess && pictureError === null) {
      history.push("/dashboard");
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          name: selectedMoneybox ? selectedMoneybox.name : moneybox.failedMoneyboxName ? moneybox.failedMoneyboxName : "",
          fantasyName: selectedMoneybox ? selectedMoneybox.fantasyName : moneybox.failedMoneyboxFantasyName ? moneybox.failedMoneyboxFantasyName : "",
        }}
        validationSchema={schemas.MoneyboxSchema}
        onSubmit={submitMoneybox}
      >
        {({ values }) => {
          return (
            <Form className="form register-form">
              {selectedMoneybox ? (
                <div className="img-container">
                  <img ref={imageRef} src={urlHelper.buildImageUrl(selectedMoneybox.id)} alt="alcancia" />
                </div>
              ) : null}
              <Field
                className="row-field"
                name="fantasyName"
                component={FormTextField}
                type="text"
                placeholder="Nombre de la alcancía"
              />
              <div className="row-field texts-row">
                <p>
                  Por ejemplo: <span className="example">Empleados del Ministerio de Turismo</span>
                </p>
                <p>No incluir la palabra "alcancía". No debe superar los 50 caracteres.</p>
              </div>
              <Field
                className="row-field"
                name="name"
                component={FormTextField}
                type="text"
                placeholder="Nombre para la dirección web"
                disabled={Boolean(selectedMoneybox)}
                startAdornment={`${window.location.origin}/`}
              />
              <div className="row-field texts-row">
                <p>
                  Por ejemplo: <span className="example">empleados-mintur</span>
                </p>
                <p>
                  No incluir la palabra "alcancía". Ingresar sólo letras minúsculas y guiones "-". El nombre debe tener
                  entre 3 y 18 caracteres.
                </p>
              </div>
              <div className="upload-btn-wrapper">
                <TextField
                  margin="none"
                  variant="outlined"
                  fullWidth
                  className="form-line-right"
                  name="picture"
                  value={selectedPhoto ? selectedPhoto.name : ""}
                  disabled={true}
                />
                <label className={loadingImage ? "disabled-label" : ""} htmlFor="photoUpload">
                  {loadingImage ? <CircularProgress size={20} thickness={6} /> : "Seleccionar"}
                </label>
                <input
                  type="file"
                  id="photoUpload"
                  accept="image/*"
                  ref={photoInput}
                  onChange={() => onPhotoSelected(photoInput && photoInput.current ? photoInput.current?.files : null)}
                />
              </div>
              <div className="row-field texts-row">Agregá una imagen o logo que represente al grupo que participa en esta alcancía. Formato JPEG o PNG. Hasta 5Mb. Idealmente de fondo blanco.</div>
              <div className="form-row">
                <span className="row-field" />
                <div className="row-field button-row-field">
                  <Button type="submit" color="secondary" variant="contained" disableElevation>
                    {(moneybox.creatingMoneybox || updating) && moneybox.updateMoneyboxErrorMessage === null ? (
                      <CircularProgress style={{ color: "#FFF" }} size={20} thickness={6} />
                    ) : selectedMoneybox ? (
                      "Guardar cambios"
                    ) : (
                      "Confirmar"
                    )}
                  </Button>
                </div>
              </div>
            </Form>);
        }}
      </Formik>
      <CustomSnackbar
        open={moneybox.createMoneyboxSuccess || moneybox.createMoneyboxErrorMessage !== null}
        message={
          moneybox.createMoneyboxSuccess ? "Se creó la alcancía correctamente" : moneybox.createMoneyboxErrorMessage!
        }
        handleClose={closeSnack}
        type={moneybox.createMoneyboxSuccess ? 0 : 1}
      />
      <CustomSnackbar
        open={updating && (moneybox.updateMoneyboxSuccess || moneybox.updateMoneyboxErrorMessage !== null)}
        message={
          pictureError !== null
            ? pictureError
            : moneybox.updateMoneyboxSuccess
              ? "Se actualizó la alcancía correctamente"
              : "Ocurrió un error al actualizar la alcancía"
        }
        handleClose={closeSnack}
        type={!moneybox.updateMoneyboxSuccess || pictureError !== null ? 1 : 0}
      />
    </>
  );
}

export default connector(withRouter(MoneyboxForm));
