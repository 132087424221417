import {
  DONATION_CONFIRMING,
  DONATION_CONFIRM_SUCCESS,
  DONATION_CONFIRM_ERROR,
  DONATION_FETCHING,
  DONATION_FETCH_SUCCESS,
  DONATION_FETCH_ERROR,
  DONATION_FINALIZING,
  DONATION_FINALIZE_SUCCESS,
  DONATION_FINALIZE_ERROR,
  DonationDataTypes,
  LOAD_LANDING,
  LOGOUT,
  LogoutActionTypes,
  SET_DONATION,
  CLEAR_INSTRUMENT,
} from "../config/ActionTypes";
import { Donation, DonationState } from "../config/types";

export const initialState: DonationState = {
  loadingLanding: true,
  selectedDonation: null,
  confirmingDonation: false,
  plexoUrl: null,
  donationId: null,
  confirmDonationErrorMessage: null,
  fetchingDonation: false,
  issuer: null,
  fetchDonationErrorMessage: null,
  finalizingDonation: false,
  finalizeDonationSuccess: false,
  totals: null,
  finalizeDonationErrorMessage: null,
};

export function donationsReducer(state = initialState, action: DonationDataTypes | LogoutActionTypes): DonationState {
  switch (action.type) {
    case LOAD_LANDING:
      return {
        ...state,
        loadingLanding: action.value,
      };

    case SET_DONATION:
      return {
        ...state,
        selectedDonation: action.donation,
        plexoUrl: null,
      };

    case DONATION_CONFIRMING:
      return {
        ...state,
        confirmingDonation: true,
        plexoUrl: null,
        confirmDonationErrorMessage: null,
      };

    case DONATION_CONFIRM_SUCCESS:
      return {
        ...state,
        confirmingDonation: false,
        plexoUrl: action.confirmDonationResponse.plexoUrl,
        donationId: action.confirmDonationResponse.donationId,
        confirmDonationErrorMessage: null,
      };

    case DONATION_CONFIRM_ERROR:
      return {
        ...state,
        confirmingDonation: false,
        plexoUrl: null,
        confirmDonationErrorMessage: action.error,
      };

    case DONATION_FETCHING:
      return {
        ...state,
        fetchingDonation: true,
        issuer: null,
        fetchDonationErrorMessage: null,
      };

    case DONATION_FETCH_SUCCESS:
      const fetchedDonation: Donation = {
        moneyboxId: action.donationResponse.donationMoneyboxId,
        currency: action.donationResponse.paymentCurrency,
        value: action.donationResponse.paymentAmount,
      };

      return {
        ...state,
        fetchingDonation: false,
        issuer: action.donationResponse.donationInstrument,
        selectedDonation: fetchedDonation,
        donationId: action.donationResponse.donationId,
        fetchDonationErrorMessage: null,
      };

    case DONATION_FETCH_ERROR:
      return {
        ...state,
        fetchingDonation: false,
        issuer: null,
        fetchDonationErrorMessage: action.error,
      };

    case CLEAR_INSTRUMENT:
      return {
        ...state,
        issuer: null,
      };

    case DONATION_FINALIZING:
      return {
        ...state,
        finalizingDonation: true,
        finalizeDonationSuccess: false,
        finalizeDonationErrorMessage: null,
      };

    case DONATION_FINALIZE_SUCCESS:
      return {
        ...state,
        finalizingDonation: false,
        finalizeDonationSuccess: true,
        totals: action.totals,
        finalizeDonationErrorMessage: null,
      };

    case DONATION_FINALIZE_ERROR:
      return {
        ...state,
        finalizingDonation: false,
        finalizeDonationSuccess: false,
        finalizeDonationErrorMessage: action.error,
      };

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
