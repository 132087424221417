import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RootState, Moneybox } from "../../store/config/types";
import { connect, ConnectedProps } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../store/config/ActionTypes";
import { selectMoneybox } from "../../store/action_creators/moneybox.actions";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { urlHelper } from "../../helpers/urlHelper";
import Image from "material-ui-image";

const mapStateToProps = (state: RootState) => ({
  moneybox: state.moneybox,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  selectMoneybox: (moneybox: Moneybox) => dispatch(selectMoneybox(moneybox)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function Moneyboxes({ moneybox, selectMoneybox, history }: PropsType) {
  const goBack = () => {
    history.push("/landing");
  };

  const changeMoneybox = (moneybox: Moneybox) => {
    selectMoneybox(moneybox);
    history.push("/dashboard");
  };

  return (
    <div className="moneyboxes">
      <div className="title">
        <div className="left">
          <h2>Mis alcancías</h2>
          <p>Seleccioná una para acceder</p>
        </div>
        <span className="blue-link" onClick={goBack}>
          Volver
        </span>
      </div>
      <div className="moneybox-list">
        {moneybox.moneyboxes &&
          moneybox.moneyboxes.map((m, index) => {
            const isSelected = m.id === moneybox.selectedMoneybox?.id;

            return (
              <div className="moneybox" key={index} onClick={() => changeMoneybox(m)}>
                <div className={`moneybox-content ${isSelected ? "selected-moneybox-content" : ""}`}>
                  {isSelected ? (
                    <CheckCircleOutlineIcon className="floating-icon selected-icon" fontSize="small" />
                  ) : (
                    <>
                      <CheckCircleOutlineIcon className="floating-icon hidden-icon" fontSize="small" />
                      <RadioButtonUncheckedIcon className="floating-icon" fontSize="small" />
                    </>
                  )}
                  <Image
                    imageStyle={{ position: "inherit", height: "auto", width: "auto", maxHeight: "80px" }}
                    style={{ display: "flex", paddingTop: 0 }}
                    src={urlHelper.buildImageUrl(m.id)}
                    alt="alcancia"
                  />
                </div>
                <span className="name">{m.fantasyName}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default connector(withRouter(Moneyboxes));
