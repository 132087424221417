import React, { useEffect, useMemo, useState } from "react";
import QRPdf from "./QRPdf";
import QRCode from "qrcode.react";
import { Document, PDFDownloadLink } from "@react-pdf/renderer";
import { Link, Moneybox } from "../../../store/config/types";

interface DownloadQRListProps {
  text?: string;
  selectedMoneybox: Moneybox;
  selectedLinks?: Link[];
}

function DownloadQRList({ text, selectedMoneybox, selectedLinks }: DownloadQRListProps) {
  const [qrs, setQrs] = useState<string[] | undefined | null>(null);

  useEffect(() => {
    if (qrs === null || qrs?.length !== selectedLinks?.length) {
      let list: string[] = [];
      selectedLinks?.forEach((l) => {
        const qrCodeCanvas: any = document.querySelector(`#id${l.code} canvas`);
        const qrCodeDataUri = qrCodeCanvas?.toDataURL("image/jpg", 0.3);
        list.push(qrCodeDataUri);
      });
      setQrs(list);
    }
  }, [qrs, setQrs, selectedLinks]);

  const qrsPdf = qrs
    ? selectedLinks?.map((l, index) => {
        return <QRPdf key={index} qrString={qrs![index]} selectedMoneybox={selectedMoneybox} selectedLink={l} />;
      })
    : null;

  const DownloadPdf = () => {
    return useMemo(
      () => (
        <PDFDownloadLink document={<Document>{qrsPdf}</Document>} fileName={`${selectedMoneybox.name}_QRs`} style={{ textDecoration: "none" }}>
          {({ loading }) =>
            loading ? "Cargando..." : <span className="blue-link">{text ? text : "Descargar todos los QR"}</span>
          }
        </PDFDownloadLink>
      ),
      []
    );
  };

  return (
    <>
      {selectedLinks &&
        selectedLinks.map((l) => {
          return (
            <div key={l.code} id={`id${l.code}`}>
              <QRCode
                value={`${window.location.origin}/landing?code=${l.code}`}
                key={l.code}
                renderAs="canvas"
                style={{ display: "none" }}
              />
            </div>
          );
        })}
      <DownloadPdf />
    </>
  );
}

export default DownloadQRList;
