import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { urlHelper } from "../../helpers/urlHelper";
import { fetchDonation } from "../../store/action_creators/donations.actions";
import { AppActions } from "../../store/config/ActionTypes";
import { RootState } from "../../store/config/types";
import { Loading } from "../Loading";
import { ErrorPage } from "../ErrorPage";

const mapStateToProps = (state: RootState) => ({
  donations: state.donations,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  fetchDonation: (donationId: string) => dispatch(fetchDonation(donationId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function Redirect({ donations, fetchDonation, history }: PropsType) {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    let urlVars = urlHelper.parseQueryString();

    if (urlVars.errorcode) {
      setHasError(true);
    } else if (
      urlVars.donationId &&
      !donations.fetchingDonation &&
      !donations.fetchDonationErrorMessage &&
      !donations.issuer
    ) {
      fetchDonation(urlVars.donationId);
    }
  }, [donations.fetchingDonation, donations.fetchDonationErrorMessage, donations.issuer, fetchDonation]);

  useEffect(() => {
    if (donations.selectedDonation) {
      history.push("/checkout");
    }
  }, [donations.selectedDonation, history]);

  if (hasError) {
    return <ErrorPage />;
  }
  return <Loading />;
}

export default connector(withRouter(Redirect));
