import React, { useEffect, useState } from "react";
import { RootState, ConfirmResetPasswordRequest } from "../../store/config/types";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../store/config/ActionTypes";
import { confirmResetPassword } from "../../store/action_creators/auth.actions";
import { connect, ConnectedProps } from "react-redux";
import { urlHelper } from "../../helpers/urlHelper";
import { Lottie } from "@crello/react-lottie";
import loadAnimation from "../../assets/redLoading.json";
import successAnimation from "../../assets/success.json";
import errorAnimation from "../../assets/error.json";
import { RouteComponentProps, withRouter } from "react-router-dom";

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  resetPassword: ({ id, token }: ConfirmResetPasswordRequest) => dispatch(confirmResetPassword({ id, token })),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function ConfirmReset({ auth, resetPassword, history }: PropsType) {
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);

      let urlVars = urlHelper.parseQueryString();

      if (urlVars.id && urlVars.token) {
        resetPassword({ id: urlVars.id, token: urlVars.token });
      }
    }
  }, [mounted, setMounted, resetPassword]);

  const goToLogin = () => {
    history.push("/login");
  };

  return (
    <div className="confirm-reset-password">
      {auth.confirmResetPasswordSuccess ? (
        <div className="success">
          <Lottie height="200px" width="200px" config={{ animationData: successAnimation, loop: false }} />
          <span>Se ha enviado la nueva contraseña a tu correo electrónico</span>
          <p className="blue-link" onClick={goToLogin}>
            Volver al login
          </p>
        </div>
      ) : auth.confirmResetPasswordErrorMessage !== null ? (
        <div className="error">
          <Lottie height="100px" width="100px" config={{ animationData: errorAnimation, loop: true }} />
          <span>Ocurrió un error al restablecer la contraseña</span>
          <p className="blue-link" onClick={goToLogin}>
            Volver al login
          </p>
        </div>
      ) : (
        <Lottie height="200px" width="200px" config={{ animationData: loadAnimation, loop: true }} />
      )}
    </div>
  );
}

export default connector(withRouter(ConfirmReset));
