import React, { ChangeEvent } from "react";
import { FormControl, InputLabel, MenuItem, Select, makeStyles } from "@material-ui/core";
import { FieldProps } from "formik";

const useSelectStyles = makeStyles({
  root: {
    width: "100%",
  },
  inputRoot: {
    padding: "12px",
  },
  input: {
    top: 12,
    left: 18,
  },
  select: {
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
});

interface Option {
  id: string;
  value: string;
}

interface SelectProps {
  label: string;
  value: string;
  options: Option[];
  setValue(value: string): void;
}

type FormSelectProps = FieldProps & React.HTMLProps<HTMLSelectElement> & SelectProps;

function FormSelectField({ field, form, label, value, setValue, options, ...props }: FormSelectProps) {
  const classes = useSelectStyles();

  const onSelectChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>): void => {
    form.setFieldValue(field.name, event.target.value);
  };

  return (
    <FormControl className={props.className} margin="normal" variant="outlined" classes={{ root: classes.root }}>
      <InputLabel classes={{ root: classes.input }}>{label}</InputLabel>
      <Select
        classes={{ root: classes.inputRoot, select: classes.select }}
        value={options ? options.find((o) => o.id === field.value)?.id : 0}
        onChange={onSelectChange}
      >
        {options.map((option, index) => (
          <MenuItem value={option.id} key={index}>
            {option.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default FormSelectField;
