import { Dialog, Button, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { ConnectedProps, connect } from "react-redux";
import {
  Switch,
  Route,
  RouteComponentProps,
  withRouter,
  Redirect as RouteRedirect,
  useLocation,
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { ThunkDispatch } from "redux-thunk";
import Cookies from "universal-cookie";
import { Footer } from "../components/common/Footer";
import { Header } from "../components/common/Header";
import { ScrollToTop } from "../components/common/ScrollToTop";
import { urlHelper } from "../helpers/urlHelper";
import { AppActions } from "../store/config/ActionTypes";
import { RootState } from "../store/config/types";
import { fetchMoneyboxes } from "../store/action_creators/moneybox.actions";
import { Checkout } from "./Checkout";
import { ConfirmReset } from "./ConfirmReset";
import { Dashboard } from "./Dashboard";
import { FAQs } from "./FAQs";
import { Landing } from "./Landing";
import { Loading } from "./Loading";
import { Login } from "./Login";
import { MailSent } from "./MailSent";
import { Moneyboxes } from "./Moneyboxes";
import { MoneyboxProfile } from "./MoneyboxProfile";
import { Redirect } from "./Redirect";
import { Register } from "./Register";
import { Resend } from "./Resend";
import { Reset } from "./Reset";
import { Result } from "./Result";
import { User } from "./User";
import { Verify } from "./Verify";

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  donations: state.donations,
  moneybox: state.moneybox,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  fetchMoneyboxes: () => dispatch(fetchMoneyboxes()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function Main({ auth, donations, moneybox, fetchMoneyboxes, history }: PropsType) {
  const [open, setOpen] = useState<boolean>(false);
  const isDetailedPage = urlHelper.isDetailedPage(history.location.pathname);
  const cookies = new Cookies();
  const location = useLocation();

  ReactGA.initialize(TRACKING_ID || "");

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  useEffect(() => {
    const hasToken = cookies.get("token") !== undefined;
    const isPrivatePage = urlHelper.isOnPrivateUrl(history.location.pathname);

    if (hasToken) {
      if (!moneybox.loadingMoneyboxes && !moneybox.moneyboxesErrorMessage && !moneybox.moneyboxes) {
        fetchMoneyboxes();
      }
    } else {
      if (isPrivatePage) {
        setOpen(true);
        history.push("/login");
      }
    }
  }, [
    auth.loggedIn,
    moneybox.loadingMoneyboxes,
    moneybox.moneyboxesErrorMessage,
    moneybox.moneyboxes,
    fetchMoneyboxes,
    cookies,
    history,
  ]);

  return (
    <TransitionGroup>
      <CSSTransition
        key={history.location.key}
        classNames="fade"
        timeout={{ enter: 300, exit: 0 }}
        unmountOnExit
      >
        <div className="main">
          {isDetailedPage ? <Header /> : null}
          <ScrollToTop />
          <div className={isDetailedPage ? "content" : ""}>
            <Switch>
              <Route path="/loading" component={Loading} />
              <Route path="/landing" component={Landing} />
              <Route path="/redirect" component={Redirect} />
              <Route path="/checkout" component={Checkout} />
              <Route path="/result" component={Result} />
              <Route path="/login" component={Login} />
              <Route path="/resend" component={Resend} />
              <Route path="/reset" component={Reset} />
              <Route path="/confirm" component={ConfirmReset} />
              <Route path="/register" component={Register} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/verify" component={Verify} />
              <Route path="/moneyboxes" component={Moneyboxes} />
              <Route path="/user" component={User} />
              <Route path="/mailSent/:mail" component={MailSent} />
              <Route path="/moneybox" component={MoneyboxProfile} />
              <Route path="/newMoneybox" component={() => <MoneyboxProfile isNew />} />
              <Route path="/faqs" component={FAQs} />
              <Route
                path="/plexo"
                component={() => {
                  if (donations.plexoUrl) {
                    window.location.href = donations.plexoUrl;
                  }
                  if (donations.loadingLanding) {
                    history.push("/landing");
                  }
                  return <Loading />;
                }}
              />
              <Route
                render={() => {
                  return <RouteRedirect to={{ pathname: "/landing" }} />;
                }}
              />
            </Switch>
          </div>
          {(!donations.loadingLanding && history.location.pathname !== "/plexo") ||
          history.location.pathname === "/result" ||
          history.location.pathname === "/redirect" ||
          isDetailedPage ? (
            <Footer />
          ) : null}
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Debes volver a iniciar sesión.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary" autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default connector(withRouter(Main));
