import React, { Dispatch, SetStateAction } from "react";
import { options } from "../../../data/options";
import { currencyHelper } from "../../../helpers/currencyHelper";
import { Currency } from "../../../store/config/enums";
import { Donation, MoneyboxState } from "../../../store/config/types";
import SupportedIssuers from "../../common/SupportedIssuers/SupportedIssuers";
import { DonationOption } from "./types";

interface SelectedOptionProps {
  advanceToDetails({ moneyboxId, currency, value }: Donation): void;
  theSelectedOption: DonationOption | null;
  setTheSelectedOption: Dispatch<SetStateAction<DonationOption | null>>;
  moneybox: MoneyboxState;
}

function SelectedOption({
  advanceToDetails,
  theSelectedOption,
  setTheSelectedOption,
  moneybox,
}: SelectedOptionProps) {
  return (
    <div className="options selected-option">
      <div
        className="option"
        onClick={() =>
          advanceToDetails({
            moneyboxId: moneybox.validatedMoneybox
              ? moneybox.validatedMoneybox?.id
              : options.anonMoneybox,
            currency: theSelectedOption!.currency,
            value: theSelectedOption!.amount,
          })
        }
      >
        <div
          className={`option-content ${
            theSelectedOption!.currency === Currency.DOLAR
              ? "dollar-option-content"
              : ""
          }`}
        >
          <span className="donate">Donar</span>
          <p>
            {currencyHelper.getCurrencyToken(theSelectedOption!.currency)}
            <span className="amount">{theSelectedOption!.amount}</span>
          </p>
        </div>
      </div>
      <p className="blue-link" onClick={() => setTheSelectedOption(null)}>
        Cambiar de monto
      </p>
      <SupportedIssuers />
    </div>
  );
}

export default SelectedOption;
