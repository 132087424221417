export const errorHelper = {
  getErrorString,
};

function getErrorString(code: number): string {
  switch (code) {
    case 462:
      return "La cuenta del usuario es inválida";

    case 470: 
      return "El mail del usuario no está verificado";

    case 510:
      return "Debes seleccionar una imagen";

    case 511:
      return "El tipo de la imagen no es correcto";

    case 512:
      return "El tamaño de la imagen no es correcto";

    case 601:
      return "El nombre de la alcancía ya existe";

    case 602:
      return "El nombre de la alcancía es inválido";

    case 464:
      return "Ha ocurrido un error inesperado";

    default:
      return "Ha ocurrido un error inesperado";
  }
}
