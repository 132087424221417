import { Dispatch } from "redux";
import { donationService } from "../../services/donation.service";
import {
  DONATION_CONFIRMING,
  DONATION_CONFIRM_SUCCESS,
  DONATION_CONFIRM_ERROR,
  DonationConfirmTypes,
  DONATION_FETCHING,
  DONATION_FETCH_SUCCESS,
  DONATION_FETCH_ERROR,
  DonationFetchTypes,
  DONATION_FINALIZING,
  DONATION_FINALIZE_SUCCESS,
  DONATION_FINALIZE_ERROR,
  DonationFinalizeTypes,
  LOAD_LANDING,
  LoadLanding,
  SET_DONATION,
  SetDonation,
  ClearInstrumentTypes,
  CLEAR_INSTRUMENT,
} from "../config/ActionTypes";
import {
  Donation,
  ConfirmDonationRequest,
  ConfirmDonationResponse,
  Totals,
  DonationResponse,
  FinalizeDonationRequest,
} from "../config/types";

//#region Load landing

export const loadLanding = (value: boolean): LoadLanding => ({
  type: LOAD_LANDING,
  value,
});

//#endregion

//#region Set donation

export const setDonation = (donation: Donation): SetDonation => ({
  type: SET_DONATION,
  donation,
});

//#region Confirm donation

export const confirmDonation = (confirmDonationRequest: ConfirmDonationRequest) => (
  dispatch: Dispatch<DonationConfirmTypes>
) => {
  dispatch(confirmingDonation());

  return donationService.confirmDonation(confirmDonationRequest).then(
    (response) => {
      dispatch(donationConfirmSuccess(response!));
    },
    (error) => {
      dispatch(donationConfirmError(error));
    }
  );
};

const confirmingDonation = (): DonationConfirmTypes => ({
  type: DONATION_CONFIRMING,
});

const donationConfirmSuccess = (confirmDonationResponse: ConfirmDonationResponse): DonationConfirmTypes => ({
  type: DONATION_CONFIRM_SUCCESS,
  confirmDonationResponse,
});

const donationConfirmError = (error: string): DonationConfirmTypes => ({
  type: DONATION_CONFIRM_ERROR,
  error,
});

//#endregion

//#region  Fetch donation

export const fetchDonation = (donationId: string) => (dispatch: Dispatch<DonationFetchTypes>) => {
  dispatch(fetchingDonation());

  return donationService.fetchDonation({ donationId }).then(
    (response) => {
      response!.donationId = donationId;
      dispatch(donationFetchSuccess(response!));
    },
    (error) => {
      dispatch(donationFetchError(error));
    }
  );
};

const fetchingDonation = (): DonationFetchTypes => ({
  type: DONATION_FETCHING,
});

const donationFetchSuccess = (donationResponse: DonationResponse): DonationFetchTypes => ({
  type: DONATION_FETCH_SUCCESS,
  donationResponse,
});

const donationFetchError = (error: string): DonationFetchTypes => ({
  type: DONATION_FETCH_ERROR,
  error,
});

//#endregion

//#region Clear instrument

export const clearInstrument = (): ClearInstrumentTypes => ({
  type: CLEAR_INSTRUMENT,
});

//#endregion

//#region Finalize donation

export const finalizeDonation = ({ donationId, fingerprint }: FinalizeDonationRequest) => (
  dispatch: Dispatch<DonationFinalizeTypes>
) => {
  dispatch(finalizingDonation());

  return donationService.finalizeDonation({ donationId, fingerprint }).then(
    (response) => {
      const totals: Totals = {
        pesos: response ? response?.raisedAmount : 0,
        dollars: response ? response?.raisedAmountDollars : 0,
      };

      dispatch(donationFinalizeSuccess(totals));
    },
    (error) => {
      dispatch(donationFinalizeError(error));
    }
  );
};

const finalizingDonation = (): DonationFinalizeTypes => ({
  type: DONATION_FINALIZING,
});

const donationFinalizeSuccess = (totals: Totals): DonationFinalizeTypes => ({
  type: DONATION_FINALIZE_SUCCESS,
  totals,
});

const donationFinalizeError = (error: string): DonationFinalizeTypes => ({
  type: DONATION_FINALIZE_ERROR,
  error,
});

//#endregion
