import { get, put, httpDelete, postForm, putForm } from './base.service';
import { endpoints } from './endpoints';
import {
  Moneybox,
  MoneyboxRequest,
  UpdateMoneyboxRequest,
  ValidationRequest,
  ValidationResponse,
  UpdateImageRequest,
  MoneyboxTotalsResponse,
} from '../store/config/types';

export const moneyboxService = {
  fetchMoneyboxes,
  getMoneybox,
  createMoneybox,
  updateMoneybox,
  updateMoneyboxPicture,
  deleteMoneybox,
  validateMoneybox,
  getMoneyboxesTotals,
};

interface MoneyboxResponse {
  results: Moneybox[];
  currentPage: number;
  pageCount: number;
  pageSize: number;
  rowCount: number;
  totals: number;
  totalsDollar: number;
  firstRowInPage: number;
  lastRowInPage: number;
}

async function fetchMoneyboxes(): Promise<Moneybox[] | undefined> {
  return await get<MoneyboxResponse>(`${endpoints['moneyboxBase']}?page=1&limit=100`)
    .then((response) => {
      return response.parsedBody?.results;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function getMoneybox({ moneyboxId }: MoneyboxRequest): Promise<Moneybox | undefined> {
  return await get<Moneybox>(`${endpoints['moneyboxBase']}/${moneyboxId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function createMoneybox(moneyboxRequest: FormData): Promise<Moneybox | undefined> {
  return await postForm<Moneybox>(`${endpoints['moneyboxBase']}`, moneyboxRequest)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function updateMoneybox(updateMoneyboxRequest: UpdateMoneyboxRequest): Promise<Moneybox | undefined> {
  return await put<Moneybox>(
    `${endpoints['moneyboxBase']}/${updateMoneyboxRequest.moneyboxId}`,
    updateMoneyboxRequest
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function updateMoneyboxPicture(updateImageRequest: UpdateImageRequest): Promise<string | undefined> {
  let formData = new FormData();
  formData.append('file', updateImageRequest.file);

  return await putForm<string>(
    `${endpoints['moneyboxBase']}/${updateImageRequest.moneyboxId}/picture`,
    formData
  )
    .then((response) => {
      return response.stringBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function deleteMoneybox({ moneyboxId }: MoneyboxRequest): Promise<string | undefined> {
  return await httpDelete<string>(`${endpoints['moneyboxBase']}/${moneyboxId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function validateMoneybox({ code }: ValidationRequest): Promise<ValidationResponse | undefined> {
  return await get<ValidationResponse>(`${endpoints['moneyboxBase']}/validate?code=${code}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function getMoneyboxesTotals(
  businessName: string,
  from?: Date,
  to?: Date
): Promise<MoneyboxTotalsResponse | undefined> {
  let queryString = `?businessName=${businessName}`;
  if (from) queryString += `&from=${from.toISOString()}`;
  if (to) queryString += `&to=${to.toISOString()}`;

  return await get<MoneyboxTotalsResponse>(`${endpoints['getMoneyboxesTotals']}${queryString}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
