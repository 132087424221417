import React, { useEffect } from "react";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../store/config/ActionTypes";
import { RootState } from "../../../store/config/types";
import { connect, ConnectedProps } from "react-redux";
import { fetchIssuers } from "../../../store/action_creators/issuers.actions";

const mapStateToProps = (state: RootState) => ({
  issuers: state.issuers,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, any, AppActions>
) => ({
  fetchIssuers: () => dispatch(fetchIssuers()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function SupportedIssuers({ issuers, fetchIssuers }: PropsFromRedux) {
  useEffect(() => {
    if (
      !issuers.loadingIssuers &&
      issuers.issuers === null &&
      issuers.issuersErrorMessage === null
    ) {
      fetchIssuers();
    }
  }, [
    issuers.loadingIssuers,
    issuers.issuers,
    issuers.issuersErrorMessage,
    fetchIssuers,
  ]);

  return (
    <div className="payment-methods">
      <div>Medios de pago disponibles:</div>
      {issuers.issuers &&
        issuers.issuers.map((issuer) => {
          return (
            <img
              src={issuer.imageUrl}
              alt={issuer.issuer}
              key={issuer.issuerId}
            />
          );
        })}
    </div>
  );
}

export default connector(SupportedIssuers);
