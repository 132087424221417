import React from "react";
import { makeStyles, TextField, Tooltip, InputAdornment } from "@material-ui/core";
import { FieldProps } from "formik";
import ErrorIcon from "@material-ui/icons/Error";

const useTextFieldStyles = makeStyles({
  root: {
    width: "100%",
  },
  icon: {
    cursor: "pointer",
  },
});

interface CustomProps {
  startAdornment?: string;
}

type TextFieldProps = CustomProps & FieldProps & React.HTMLProps<HTMLInputElement>;

function FormTextField({ startAdornment, field, form, ...props }: TextFieldProps) {
  const classes = useTextFieldStyles();
  const hasError = (form.touched[field.name] && form.errors[field.name]) !== undefined;

  if (props.type === "number") {
    field.onChange = (event: any) => {
      console.log(event.target.value);
      form.setFieldValue(field.name, event.target.value.replace(",", "").replace(".", "").replace("-", ""))
    }
  }

  return (
    <TextField
      margin="normal"
      variant="outlined"
      className={props.className}
      error={hasError}
      classes={{ root: classes.root }}
      InputProps={{
        inputProps: { min: props.min },
        startAdornment: startAdornment ? <InputAdornment position="start">{startAdornment}</InputAdornment> : null,
        endAdornment: hasError ? (
          <Tooltip title={form.errors[field.name]!.toString()}>
            <InputAdornment position="end">
              <ErrorIcon color="error" classes={{ root: classes.icon }} />
            </InputAdornment>
          </Tooltip>
        ) : null,
      }}
      {...field}
      type={props.type}

      label={props.placeholder}
      disabled={props.disabled}
    />
  );
}

export default FormTextField;
