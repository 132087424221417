import React from "react";
import { Page, Image, View, StyleSheet, Text } from "@react-pdf/renderer";
import logo from "../../../assets/icon.png";
import { Link, Moneybox } from "../../../store/config/types";
import { currencyHelper } from "../../../helpers/currencyHelper";
import { Currency } from "../../../store/config/enums";

const color = "#DEDEDE";
const width = 300;
const separation = "24px";

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
  },
  section: {
    width: width,
  },
  linkSection: {
    width: width + 150,
  },
  logo: {
    height: 50,
    width: "auto",
  },
  image: {
    height: "100%",
    width: "100%",
  },
  qr: {
    display: "none",
  },
  separator: {
    width: width,
    margin: `${separation} 0`,
    borderTop: `1 solid ${color}`,
  },
  qrcontainer: {
    margin: `${separation} 0`,
    border: `1 solid ${color}`,
    borderRadius: 6,
    padding: 32,
    width: width,
    height: width,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  texts: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  amount: {
    margin: "8px 0",
    fontSize: 48,
    fontWeight: "bold",
    color: "#F11930",
  },
  text: {
    fontSize: 18,
    color: "#444444",
  },
  boldText: {
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 4,
  },
  smallText: {
    fontSize: 12,
    color: "#707070",
    textAlign: "center",
  },
});

interface QRPdfProps {
  qrString: string | undefined | null;
  selectedMoneybox: Moneybox;
  selectedLink?: Link;
}

function QRPdf({ qrString, selectedMoneybox, selectedLink }: QRPdfProps) {
  const currencyToken = currencyHelper.getCurrencyToken(selectedLink ? selectedLink?.currency : Currency.PESO);
  const url = selectedLink
    ? `${window.location.origin}/landing?code=${selectedLink ? selectedLink?.code : ""}`
    : `${window.location.origin}/${selectedMoneybox.fantasyName}`;

  return (
    <Page size="A4" style={styles.page}>
      <View>
        <Image style={styles.logo} source={logo} />
      </View>
      <View style={styles.separator} />
      <View style={styles.texts}>
        <Text style={styles.text}>{`Donación${selectedLink ? " de" : ""}`}</Text>
        {selectedLink ? (
          <>
            <Text style={styles.amount}>{`${currencyToken}${selectedLink ? selectedLink?.value : 0}`}</Text>
            <Text style={styles.text}>a la Teletón mediante la alcancía</Text>
            <Text style={styles.boldText}>{selectedMoneybox.fantasyName}</Text>
          </>
        ) : (
          <Text style={styles.text}>a la Teletón</Text>
        )}
      </View>
      <View style={styles.separator} />
      <View style={styles.section}>
        <Text style={styles.smallText}>
          {`Al escanear este código QR desde tu celular accederás a la página de las alcancías Teletón${
            selectedLink ? ` para donar ${currencyToken}${selectedLink?.value}` : ""
          }`}
        </Text>
      </View>
      <View style={styles.qrcontainer}>{qrString ? <Image src={qrString} style={styles.image} /> : null}</View>
      <View style={styles.linkSection}>
        <Text style={styles.smallText}>{url}</Text>
      </View>
    </Page>
  );
}

export default QRPdf;
