import React from "react";
import { Formik, Form, Field, FormikHelpers } from "formik";
import { FormSelectField } from "../../forms/FormSelectField";
import { FormTextField } from "../../forms/FormTextField";
import { Button } from "@material-ui/core";
import { Currency } from "../../../store/config/enums";
import { Link } from "../../../store/config/types";

interface Values {
  amount: string;
  currency: number;
}

interface ManualLinkProps {
  submitManualLink(link: Link): void;
}

function ManualLink({ submitManualLink }: ManualLinkProps) {
  const submit = (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    const link: Link = {
      code: "",
      currency: values.currency,
      value: Number(values.amount),
    };

    setSubmitting(false);
    submitManualLink(link);
  };

  return (
    <div className="manual-creation">
      <p>Crear link con monto específico</p>
      <Formik initialValues={{ amount: "", currency: Currency.PESO }} onSubmit={submit}>
        {({ isSubmitting, values }) => (
          <Form className="specific-amount-row">
            <Field className="specific-amount-field" name="amount" component={FormTextField} type="number" min="0" />
            <Field
              className="specific-amount-field"
              name="currency"
              component={FormSelectField}
              options={[
                { id: Currency.PESO, value: "Pesos" },
                { id: Currency.DOLAR, value: "Dólares" },
              ]}
            />
            <Button
              disabled={isSubmitting || !values["amount"]}
              classes={{
                disabled: "disabled-button",
                root: `donate-button ${values["currency"] === Currency.DOLAR ? "donate-dollar-button" : ""}`,
              }}
              color="secondary"
              variant="contained"
              type="submit"
              disableElevation
            >
              Crear link
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ManualLink;
