import React from "react";
import { Dialog, DialogContent, Button, makeStyles, DialogTitle } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Donation } from "../../../store/config/types";

const useDialogStyles = makeStyles({
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingBottom: 0,
  },
  content: {
    padding: 0,
    paddingBottom: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  greenText: {
    fontWeight: "normal",
    fontSize: 24,
    color: "#50a750",
    margin: 0,
  },
  actions: {
    marginTop: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    marginLeft: 16,
    backgroundColor: "#50a750",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#52ce52",
    },
  },
});

interface DeleteDialogProps {
  open: boolean;
  setOpen(value: boolean): void;
  donateAction(): void;
  donation: Donation | null;
}

function DollarsDialog({ open, setOpen, donateAction, donation }: DeleteDialogProps) {
  const classes = useDialogStyles();

  const donate = () => {
    donateAction();
    setOpen(false);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <Dialog className="dialog" fullWidth maxWidth="xs" open={open} onClose={handleDialogClose}>
      <DialogTitle className={classes.title}>
        <CloseIcon className="blue-link" onClick={handleDialogClose} />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <h2 className={classes.greenText}>Dólares U$S</h2>
        <p>Estás por realizar una donación en dólares.</p>
        <div className={classes.actions}>
          <span className="green-link" onClick={handleDialogClose}>
            No, cancelar
          </span>
          <Button classes={{ root: classes.button }} variant="contained" onClick={donate}>
            {`Si, donar U$S ${donation ? donation.value : ""}`}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default DollarsDialog;
