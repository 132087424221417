import React, { useEffect } from "react";
import { Lottie } from "@crello/react-lottie";
import { RedLanding } from "../../components/landing/RedLanding";
import { DonationOptions } from "../../components/landing/DonationOptions";
import { urlHelper } from "../../helpers/urlHelper";
import { RootState } from "../../store/config/types";
import { connect, ConnectedProps } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../store/config/ActionTypes";
import { loadLanding } from "../../store/action_creators/donations.actions";
import logo from "../../assets/logo.png";
import loadAnimation from "../../assets/loading.json";
import { validateMoneybox } from "../../store/action_creators/moneybox.actions";
import { Header } from "../../components/common/Header";
import { Currency } from "../../store/config/enums";
import Image from "material-ui-image";
import Cookies from "universal-cookie";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Loading } from "../Loading";

const mapStateToProps = (state: RootState) => ({
  donations: state.donations,
  moneybox: state.moneybox,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  loadLanding: (value: boolean) => dispatch(loadLanding(value)),
  validateMoneybox: (code: string) => dispatch(validateMoneybox({ code })),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function Landing({ donations, moneybox, loadLanding, validateMoneybox, history }: PropsType) {
  const cookies = new Cookies();
  const isLoggedIn = cookies.get("token") !== undefined;

  useEffect(() => {
    let urlVars = urlHelper.parseQueryString();

    if (urlVars.code) {
      localStorage.setItem("code", urlVars.code);
      validateMoneybox(urlVars.code);
    } else {
      localStorage.setItem("code", "none");
      loadLanding(false);
    }
  }, [validateMoneybox, loadLanding]);

  useEffect(() => {
    if (
      donations.loadingLanding &&
      (moneybox.validateMoneyboxErrorMessage !== null || moneybox.validateMoneyboxSuccess)
    ) {
      loadLanding(false);
    }
  }, [donations.loadingLanding, moneybox.validateMoneyboxErrorMessage, moneybox.validateMoneyboxSuccess, loadLanding]);

  if (isLoggedIn) {
    history.push("/dashboard");
    return <Loading></Loading>;
  }
  if (donations.loadingLanding) {
    return (
      <div className="loading-landing">
        <img src={logo} alt="logo" />
        <p>Alcancías Teletón</p>
        <Lottie height="200px" width="200px" config={{ animationData: loadAnimation, loop: true }} />
      </div>
    );
  }

  const validateMoneyboxSuccess = moneybox.validateMoneyboxSuccess;

  if (validateMoneyboxSuccess) {
    return (
      <div className="landing">
        <Header />
        <div className="content">
          <div className="moneybox-data">
            <Image
              imageStyle={{ position: "inherit", height: 80, width: "auto" }}
              style={{ display: "flex", paddingTop: 0 }}
              src={urlHelper.buildImageUrl(moneybox.validatedMoneybox ? moneybox.validatedMoneybox?.id : "")}
              alt=""
            />
            <p className="name">{moneybox.validatedMoneybox?.fantasyName}</p>
          </div>
          <DonationOptions
            selectedOption={{
              amount: moneybox.validatedLink ? moneybox.validatedLink?.value : 10,
              currency: moneybox.validatedLink ? moneybox.validatedLink?.currency : Currency.PESO,
            }}
            invitation={moneybox.validatedMoneybox?.fantasyName}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="landing">
      <RedLanding />
      <div className="white-line" />
      <DonationOptions />
    </div>
  );
}

export default connector(withRouter(Landing));
