import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import FilterCenterFocusIcon from "@material-ui/icons/FilterCenterFocus";
import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { currencyHelper } from "../../../helpers/currencyHelper";
import { numberHelper } from "../../../helpers/numberHelper";
import { Currency } from "../../../store/config/enums";
import { Link, RootState } from "../../../store/config/types";
import { DownloadQR } from "../DownloadQR";

interface LinkOptionProps {
  setLinkToDelete(link: Link, currency: string): void;
  link: Link;
}

const mapStateToProps = (state: RootState) => ({
  moneybox: state.moneybox,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & LinkOptionProps;

function LinkOption({ setLinkToDelete, link, moneybox }: PropsType) {
  const [openCopySnackbar, setOpenCopySnackbar] = useState<boolean>(false);

  const copyToClipboard = (link: Link) => {
    navigator.clipboard.writeText(`${window.location.origin}/landing?code=${link.code.toString()}`);
    setOpenCopySnackbar(true);
  };

  return (
    <div className="link">
      <div className={`link-content ${link.currency === Currency.DOLAR ? "dollar-link-content" : ""}`}>
        <span className="copy-url" onClick={() => copyToClipboard(link)}>
          <FileCopyIcon className="icon" fontSize="inherit" />
          Copiar link
        </span>
        <p>
          {currencyHelper.getCurrencyToken(link.currency)}
          <span className="amount">{numberHelper.getNumberWithDots(link.value)}</span>
        </p>
        <div className="actions">
          <div className={`icon-blue-link ${link.currency === Currency.DOLAR ? "green-link" : "blue-link"}`}>
            <FilterCenterFocusIcon className="icon" fontSize="inherit" />
            <DownloadQR selectedMoneybox={moneybox.selectedMoneybox!} selectedLink={link} />
          </div>
          <span
            className={`icon-blue-link ${link.currency === Currency.DOLAR ? "green-link" : "blue-link"}`}
            onClick={() => setLinkToDelete(link, "$")}
          >
            <DeleteIcon className="icon" fontSize="inherit" /> Eliminar
          </span>
        </div>
      </div>
      {/* <CustomSnackbar
        open={openCopySnackbar}
        message={`Link copiado al portapapeles: ${window.location.host}/landing?code=${link.code.toString()}`}
        handleClose={() => setOpenCopySnackbar(false)}
        type={0}
      /> */}
      <Dialog
        open={openCopySnackbar}
        onClose={() => setOpenCopySnackbar(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="copied-dialog-title">
          Link copiado al portapapeles
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{`${
            window.location.origin
          }/landing?code=${link.code.toString()}`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCopySnackbar(false)} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default connector(LinkOption);
