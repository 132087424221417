import { Currency } from "../store/config/enums";

export const currencyHelper = {
  getCurrencyToken,
  getCurrencyName,
};

function getCurrencyToken(currency: Currency): string {
  if (currency === Currency.PESO) {
    return "$";
  } else {
    return "U$S";
  }
}

function getCurrencyName(currency: Currency): string {
  if (currency === Currency.PESO) {
    return "Pesos";
  } else {
    return "Dólares";
  }
}
