import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { UserForm } from "../../components/forms/UserForm";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/config/types";

const mapStateToProps = (state: RootState) => ({
  moneybox: state.moneybox,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function User({ moneybox, history }: PropsType) {
  const goBack = () => {
    history.goBack();
  };

  return (
    <div className="profile">
      <div className="title">
        <div className="left">
          <h2>Usuario</h2>
          <p>Cambio de información</p>
        </div>
        <span className="blue-link" onClick={goBack}>
          Volver
        </span>
      </div>
      {moneybox.selectedMoneybox ? <UserForm selectedUser={moneybox.selectedMoneybox.admin!} /> : null}
    </div>
  );
}

export default connector(withRouter(User));
