import { get, post, put } from './base.service'
import {
  User,
  LoginRequest,
  ChangePasswordRequest,
  RegisterRequest,
  ResetPasswordRequest,
  ConfirmResetPasswordRequest,
  ConfirmEmailRequest,
} from '../store/config/types'
import { endpoints } from './endpoints'

export const authService = {
  login,
  register,
  checkEmailAvailability,
  modifyPassword,
  resetPassword,
  confirmResetPassword,
  confirmEmail,
  resendEmail,
}

interface TokenResponse {
  token: string
}

async function login({ user }: LoginRequest): Promise<string | undefined> {
  return await post<string>(`${endpoints['authLogin']}`, user)
    .then((response) => {
      return response.stringBody
    })
    .catch((error) => {
      throw new Error(error)
    })
}

async function register({ user }: RegisterRequest): Promise<User | undefined> {
  return await post<User>(`${endpoints['authRegister']}`, user)
    .then((response) => {
      return response.parsedBody
    })
    .catch((error) => {
      throw new Error(error)
    })
}

async function checkEmailAvailability(email: string): Promise<boolean | undefined> {
  return await get<boolean>(`${endpoints['authEmailVerification']}?email=${email}`)
    .then((response) => {
      return response.parsedBody
    })
    .catch((error) => {
      throw new Error(error)
    })
}

async function modifyPassword(changePasswordRequest: ChangePasswordRequest): Promise<string> {
  return await put<string>(`${endpoints['authModifyPassword']}`, changePasswordRequest)
    .then((response) => {
      return response.stringBody!
    })
    .catch((error) => {
      throw new Error(error)
    })
}

async function resetPassword(resetPasswordRequest: ResetPasswordRequest): Promise<string> {
  return await post<string>(`${endpoints['authResetPassword']}`, resetPasswordRequest)
    .then((response) => {
      return response.stringBody!
    })
    .catch((error) => {
      throw new Error(error)
    })
}

async function confirmResetPassword({ id, token }: ConfirmResetPasswordRequest): Promise<string> {
  return await get<string>(`${endpoints['authResetPassword']}?id=${id}&token=${token}`)
    .then((response) => {
      return response.stringBody!
    })
    .catch((error) => {
      throw new Error(error)
    })
}

async function confirmEmail({
  id,
  token,
}: ConfirmEmailRequest): Promise<TokenResponse | undefined> {
  return await get<TokenResponse>(`${endpoints['authConfirmEmail']}?id=${id}&token=${token}`)
    .then((response) => {
      return response.parsedBody
    })
    .catch((error) => {
      throw new Error(error)
    })
}

async function resendEmail(email: string): Promise<boolean | undefined> {
  return await post<boolean>(`${endpoints['authResendEmail']}`, { email })
    .then((response) => {
      return response.parsedBody
    })
    .catch((error) => {
      throw new Error(error)
    })
}
