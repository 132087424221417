import { Dispatch } from "redux";
import { linksService } from "../../services/links.service";
import { MoneyboxRequest, Link, LinkRequest, DeleteLinkRequest, ShareLinkRequest } from "../config/types";
import {
  FetchLinksTypes,
  LOADING_LINKS,
  ADD_LINKS,
  LINKS_FAILED,
  CreateLinkTypes,
  CREATING_LINK,
  CREATE_LINK_SUCCESS,
  CREATE_LINK_FAILED,
  DELETING_LINK,
  DELETE_LINK_SUCCESS,
  DELETE_LINK_FAILED,
  DeleteLinkTypes,
  ShareLinkTypes,
  SHARING_LINK,
  SHARE_LINK_SUCCESS,
  SHARE_LINK_FAILED,
} from "../config/ActionTypes";

//#region Fetch links

export const fetchLinks = ({ moneyboxId }: MoneyboxRequest) => {
  return (dispatch: Dispatch<FetchLinksTypes>) => {
    dispatch(loadingLinks());

    return linksService.fetchLinks({ moneyboxId }).then(
      (response) => {
        dispatch(addLinks(response!));
      },
      (error) => {
        dispatch(linksFailed(error));
      }
    );
  };
};

export const loadingLinks = (): FetchLinksTypes => ({
  type: LOADING_LINKS,
});

export const addLinks = (links: Link[]): FetchLinksTypes => ({
  type: ADD_LINKS,
  links,
});

export const linksFailed = (error: string): FetchLinksTypes => ({
  type: LINKS_FAILED,
  error,
});

//#endregion

//#region Create link

export const createLink = ({ currency, value, moneyboxId }: LinkRequest) => {
  return (dispatch: Dispatch<CreateLinkTypes>) => {
    dispatch(creatingLink());

    const linkRequest: LinkRequest = {
      currency,
      value,
      moneyboxId,
    };

    return linksService.createLink(linkRequest).then(
      (response) => {
        dispatch(createLinkSuccess(response!));
      },
      (error) => {
        dispatch(createLinkFailed(error));
      }
    );
  };
};

export const creatingLink = (): CreateLinkTypes => ({
  type: CREATING_LINK,
});

export const createLinkSuccess = (link: Link): CreateLinkTypes => ({
  type: CREATE_LINK_SUCCESS,
  link,
});

export const createLinkFailed = (error: string): CreateLinkTypes => ({
  type: CREATE_LINK_FAILED,
  error,
});

//#endregion

//#region Delete link

export const deleteLink = ({ moneyboxId, linkId }: DeleteLinkRequest) => {
  return (dispatch: Dispatch<DeleteLinkTypes>) => {
    dispatch(deletingLink());

    return linksService.deleteLink({ moneyboxId, linkId }).then(
      (response) => {
        dispatch(deleteLinkSuccess(linkId));
      },
      (error) => {
        dispatch(deleteLinkFailed(error));
      }
    );
  };
};

export const deletingLink = (): DeleteLinkTypes => ({
  type: DELETING_LINK,
});

export const deleteLinkSuccess = (linkId: string): DeleteLinkTypes => ({
  type: DELETE_LINK_SUCCESS,
  linkId,
});

export const deleteLinkFailed = (error: string): DeleteLinkTypes => ({
  type: DELETE_LINK_FAILED,
  error,
});

//#endregion

//#region Share link

export const ShareLink = ({ moneyboxId, linkId, via }: ShareLinkRequest) => {
  return (dispatch: Dispatch<ShareLinkTypes>) => {
    dispatch(sharingLink());

    return linksService.shareLink({ moneyboxId, linkId, via }).then(
      (response) => {
        if (response) {
          dispatch(shareLinkSuccess());
        }
      },
      (error) => {
        dispatch(shareLinkFailed(error));
      }
    );
  };
};

export const sharingLink = (): ShareLinkTypes => ({
  type: SHARING_LINK,
});

export const shareLinkSuccess = (): ShareLinkTypes => ({
  type: SHARE_LINK_SUCCESS,
});

export const shareLinkFailed = (error: string): ShareLinkTypes => ({
  type: SHARE_LINK_FAILED,
  error,
});

//#endregion
