import React from "react";
import { Lottie } from "@crello/react-lottie";
import loadAnimation from "../../assets/redLoading.json";

function Loading() {
  return (
    <div className="loading-screen">
      <Lottie height="200px" width="200px" config={{ animationData: loadAnimation, loop: true }} />
    </div>
  );
}

export default Loading;
