import React, { useEffect, useState } from "react";
import { Lottie } from "@crello/react-lottie";
import loadAnimation from "../../assets/redLoading.json";
import successAnimation from "../../assets/success.json";
import errorAnimation from "../../assets/error.json";
import { urlHelper } from "../../helpers/urlHelper";
import { ConfirmEmailRequest, RootState } from "../../store/config/types";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../store/config/ActionTypes";
import { connect, ConnectedProps } from "react-redux";
import { confirmEmail } from "../../store/action_creators/auth.actions";
import { RouteComponentProps } from "react-router-dom";

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  confirmEmail: ({ id, token }: ConfirmEmailRequest) => dispatch(confirmEmail({ id, token })),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function Verify({ auth, confirmEmail, history }: PropsType) {
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);

      let urlVars = urlHelper.parseQueryString();

      if (urlVars.id && urlVars.token) {
        confirmEmail({ id: urlVars.id, token: urlVars.token });
      }
    }
  }, [mounted, setMounted, confirmEmail]);

  const advanceToSecondStep = () => {
    history.push("/register?step=2");
  };

  const verifyingUser = (
    <div className="loading-verification">
      <Lottie height="200px" width="200px" config={{ animationData: loadAnimation, loop: true }} />
      <p>Estamos verificando tu correo electrónico</p>
    </div>
  );

  const userVerified = (
    <div className="email-verified">
      <Lottie height="200px" width="200px" config={{ animationData: successAnimation, loop: false }} />
      <p>Quedaste registrado, ya puedes terminar de crear tu alcancía</p>
      <span className="blue-link" onClick={advanceToSecondStep}>
        Continuar con el registro
      </span>
    </div>
  );

  const verificationFailed = (
    <div className="verification-failed">
      <Lottie height="180px" width="180px" config={{ animationData: errorAnimation, loop: false }} />
      <p>No se pudo verificar el mail</p>
    </div>
  );

  return (
    <div className="verify">
      {auth.confirmEmailSuccess
        ? userVerified
        : auth.confirmEmailErrorMessage !== null
        ? verificationFailed
        : verifyingUser}
    </div>
  );
}

export default connector(Verify);
