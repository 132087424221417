import { Dispatch } from 'redux'
import {
  User,
  ResetPasswordRequest,
  ConfirmResetPasswordRequest,
  ChangePasswordRequest,
  ConfirmEmailRequest,
  ResendEmailRequest,
} from '../config/types'
import {
  LOGGING_IN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LoginActionTypes,
  PASSWORD_CHANGING,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CHANGE_ERROR,
  PasswordChangeTypes,
  RESETING_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  ResetPasswordTypes,
  REGISTERING,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  RegisterActionTypes,
  CHECKING_EMAIL,
  CHECK_EMAIL_SUCCESS,
  CHECK_EMAIL_ERROR,
  CheckEmailActionTypes,
  CONFIRMING_RESET_PASSWORD,
  CONFIRM_RESET_PASSWORD_SUCCESS,
  CONFIRM_RESET_PASSWORD_ERROR,
  ConfirmResetPasswordTypes,
  LogoutActionTypes,
  LOGOUT,
  ConfirmEmailTypes,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRMING_EMAIL,
  CONFIRM_EMAIL_ERROR,
  ResendEmailTypes,
  RESENDING_EMAIL,
  RESEND_EMAIL_SUCCESS,
  RESEND_EMAIL_ERROR,
} from '../config/ActionTypes'
import { authService } from '../../services/auth.service'
import Cookies from 'universal-cookie'

//#region Login

export const login = (user: User) => (dispatch: Dispatch<LoginActionTypes>) => {
  dispatch(loggingIn(user.email))

  return authService.login({ user }).then(
    (response) => {
      const cookies = new Cookies()
      cookies.set('token', response!)

      dispatch(loginSuccess())
    },
    (error) => {
      dispatch(loginError(error.message))
    }
  )
}

const loggingIn = (email: string): LoginActionTypes => ({
  type: LOGGING_IN,
  email,
})

const loginSuccess = (): LoginActionTypes => ({
  type: LOGIN_SUCCESS,
})

const loginError = (error: string): LoginActionTypes => ({
  type: LOGIN_ERROR,
  error,
})

//#endregion

//#region Logout

export const logout = (): LogoutActionTypes => ({
  type: LOGOUT,
})

//#endregion

//#region Register

export const register = (user: User) => (dispatch: Dispatch<RegisterActionTypes>) => {
  dispatch(registering())

  return authService.register({ user }).then(
    (response) => {
      dispatch(registerSuccess(response!))
    },
    (error) => {
      dispatch(registerError(error))
    }
  )
}

const registering = (): RegisterActionTypes => ({
  type: REGISTERING,
})

const registerSuccess = (user: User): RegisterActionTypes => ({
  type: REGISTER_SUCCESS,
  user,
})

const registerError = (error: string): RegisterActionTypes => ({
  type: REGISTER_ERROR,
  error,
})

//#endregion

//#region Check email availability

export const checkEmailAvailability =
  (email: string) => (dispatch: Dispatch<CheckEmailActionTypes>) => {
    dispatch(checkingEmailAvailability())

    return authService.checkEmailAvailability(email).then(
      (response) => {
        if (response) {
          dispatch(checkEmailSuccess())
        } else {
          dispatch(checkEmailError('Email ya existente.'))
        }
      },
      (error) => {
        dispatch(checkEmailError(error))
      }
    )
  }

const checkingEmailAvailability = (): CheckEmailActionTypes => ({
  type: CHECKING_EMAIL,
})

const checkEmailSuccess = (): CheckEmailActionTypes => ({
  type: CHECK_EMAIL_SUCCESS,
})

const checkEmailError = (error: string): CheckEmailActionTypes => ({
  type: CHECK_EMAIL_ERROR,
  error,
})

//#endregion

//#region Change password

export const changePassword =
  ({ email, oldPassword, newPassword }: ChangePasswordRequest) =>
  (dispatch: Dispatch<PasswordChangeTypes>) => {
    dispatch(changingPassword())

    const changePasswordRequest: ChangePasswordRequest = {
      email,
      oldPassword,
      newPassword,
    }

    return authService.modifyPassword(changePasswordRequest).then(
      (response) => {
        dispatch(changePasswordSuccess())
      },
      (error) => {
        dispatch(changePasswordError(error))
      }
    )
  }

const changingPassword = (): PasswordChangeTypes => ({
  type: PASSWORD_CHANGING,
})

const changePasswordSuccess = (): PasswordChangeTypes => ({
  type: PASSWORD_CHANGE_SUCCESS,
})

const changePasswordError = (error: string): PasswordChangeTypes => ({
  type: PASSWORD_CHANGE_ERROR,
  error,
})

//#endregion

//#region Reset password

export const resetPassword =
  ({ email }: ResetPasswordRequest) =>
  (dispatch: Dispatch<ResetPasswordTypes>) => {
    dispatch(resetingPassword())

    const resetPasswordRequest: ResetPasswordRequest = {
      email,
    }

    return authService.resetPassword(resetPasswordRequest).then(
      (response) => {
        dispatch(resetPasswordSuccess())
      },
      (error) => {
        dispatch(resetPasswordError(error))
      }
    )
  }

const resetingPassword = (): ResetPasswordTypes => ({
  type: RESETING_PASSWORD,
})

const resetPasswordSuccess = (): ResetPasswordTypes => ({
  type: RESET_PASSWORD_SUCCESS,
})

const resetPasswordError = (error: string): ResetPasswordTypes => ({
  type: RESET_PASSWORD_ERROR,
  error,
})

//#endregion

//#region Confirm reset password

export const confirmResetPassword =
  ({ id, token }: ConfirmResetPasswordRequest) =>
  (dispatch: Dispatch<ConfirmResetPasswordTypes>) => {
    dispatch(fetchingRecoveredPassword())

    return authService.confirmResetPassword({ id, token }).then(
      (response) => {
        dispatch(fetchRecoverPasswordSuccess(response!))
      },
      (error) => {
        dispatch(fetchRecoverPasswordError(error))
      }
    )
  }

const fetchingRecoveredPassword = (): ConfirmResetPasswordTypes => ({
  type: CONFIRMING_RESET_PASSWORD,
})

const fetchRecoverPasswordSuccess = (recoveredPassword: string): ConfirmResetPasswordTypes => ({
  type: CONFIRM_RESET_PASSWORD_SUCCESS,
  recoveredPassword,
})

const fetchRecoverPasswordError = (error: string): ConfirmResetPasswordTypes => ({
  type: CONFIRM_RESET_PASSWORD_ERROR,
  error,
})

//#endregion

//#region Confirm reset password

export const confirmEmail =
  ({ id, token }: ConfirmEmailRequest) =>
  (dispatch: Dispatch<ConfirmEmailTypes>) => {
    dispatch(confirmingEmail())

    return authService.confirmEmail({ id, token }).then(
      (response) => {
        const cookies = new Cookies()
        cookies.set('token', response?.token)

        dispatch(confirmEmailSuccess())
      },
      (error) => {
        dispatch(confirmEmailError(error))
      }
    )
  }

const confirmingEmail = (): ConfirmEmailTypes => ({
  type: CONFIRMING_EMAIL,
})

const confirmEmailSuccess = (): ConfirmEmailTypes => ({
  type: CONFIRM_EMAIL_SUCCESS,
})

const confirmEmailError = (error: string): ConfirmEmailTypes => ({
  type: CONFIRM_EMAIL_ERROR,
  error,
})

//#endregion

//#region Resend email

export const resendEmail =
  ({ email }: ResendEmailRequest) =>
  (dispatch: Dispatch<ResendEmailTypes>) => {
    dispatch(resendingEmail())

    return authService.resendEmail(email).then(
      (response) => {
        dispatch(resendEmailSuccess())
      },
      (error) => {
        dispatch(resendEmailError(error))
      }
    )
  }

const resendingEmail = (): ResendEmailTypes => ({
  type: RESENDING_EMAIL,
})

const resendEmailSuccess = (): ResendEmailTypes => ({
  type: RESEND_EMAIL_SUCCESS,
})

const resendEmailError = (error: string): ResendEmailTypes => ({
  type: RESEND_EMAIL_ERROR,
  error,
})

//#endregion
