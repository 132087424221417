import React, { useState, Dispatch, SetStateAction } from "react";
import { Formik, Form, Field } from "formik";
import { FormTextField } from "../FormTextField";
import { Button } from "@material-ui/core";
import { RootState, User, Admin, ChangePasswordRequest } from "../../../store/config/types";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../store/config/ActionTypes";
import { register, changePassword } from "../../../store/action_creators/auth.actions";
import { ConnectedProps, connect } from "react-redux";
import schemas from "../../../data/schemas";
import { CustomSnackbar } from "../../common/CustomSnackbar";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { authService } from "../../../services/auth.service";

interface UserValues {
  email: string;
  oldPassword: string;
  password: string;
  repeatPassword: string;
}

interface UserFormProps {
  selectedUser?: Admin;
  setEmail?: Dispatch<SetStateAction<string>>;
}

interface LooseObject {
  [key: string]: any;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  register: (user: User) => dispatch(register(user)),
  changePassword: ({ email, oldPassword, newPassword }: ChangePasswordRequest) =>
    dispatch(changePassword({ email, oldPassword, newPassword })),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & UserFormProps & RouteComponentProps<any>;

function UserForm({ auth, register, changePassword, selectedUser, setEmail, history }: PropsType) {
  const [creating, setCreating] = useState<boolean>(false);
  const [modifying, setModifying] = useState<boolean>(false);
  const [validatedEmail, setValidatedEmail] = useState<string>("");
  const [validating, setValidating] = useState<boolean>(false);
  const [mailHasError, setMailHasError] = useState<boolean>(false);

  const submitUser = (values: UserValues) => {
    if (selectedUser) {
      const changePasswordRequest: ChangePasswordRequest = {
        email: values.email,
        oldPassword: values.oldPassword,
        newPassword: values.password,
      };

      setModifying(true);
      changePassword(changePasswordRequest);
    } else {
      const user: User = {
        email: values.email,
        password: values.password,
      };

      if (setEmail) {
        setEmail(values.email);
      }

      setCreating(true);
      register(user);
    }
  };

  const closeSnack = () => {
    setCreating(false);
    setModifying(false);

    if (auth.changePasswordSuccess) {
      history.push("/dashboard");
    }
  };

  const validEmail = (email: string) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validate = async (values: UserValues) => {
    let mailValue = values.email;
    let errors: LooseObject = {};

    if (mailValue !== "" && validEmail(mailValue) && mailValue !== validatedEmail && !validating) {
      setValidating(true);
      setValidatedEmail(mailValue);

      if (!Boolean(selectedUser)) {
        await authService.checkEmailAvailability(mailValue).then(
          (response) => {
            setValidating(false);
            setMailHasError(false);
          },
          (error) => {
            setValidating(false);
            setMailHasError(true);
            errors.email = "El email ya existe en la plataforma";
            return errors;
          }
        );
      }
    } else if (mailHasError) {
      errors.email = "El email ya existe en la plataforma";
      return errors;
    }

    return errors;
  };

  return (
    <>
      <Formik
        initialValues={{
          email: selectedUser ? selectedUser.email : "",
          oldPassword: "",
          password: "",
          repeatPassword: "",
        }}
        onSubmit={submitUser}
        validate={validate}
        validateOnChange={false}
        validationSchema={selectedUser ? schemas.ModifyPasswordSchema : schemas.RegisterUserSchema}
      >
        <Form className="form register-form">
          <Field
            className="row-field"
            name="email"
            component={FormTextField}
            type="text"
            placeholder="Email del administrador"
            disabled={Boolean(selectedUser)}
          />
          {selectedUser ? (
            <Field
              className="row-field"
              name="oldPassword"
              component={FormTextField}
              type="password"
              placeholder={"Contraseña actual"}
            />
          ) : null}
          <Field
            className="row-field"
            name="password"
            component={FormTextField}
            type="password"
            placeholder={selectedUser ? "Nueva contraseña" : "Contraseña"}
          />
          <div className="row-field texts-row">
            <p>La contraseña debe tener al menos una mayúscula, una minúscula y un número.</p>
          </div>
          <Field
            className="row-field"
            name="repeatPassword"
            component={FormTextField}
            type="password"
            placeholder={`Repetir ${selectedUser ? "nueva " : ""}contraseña`}
          />
          <div className="form-row">
            <span className="row-field" />
            <div className="row-field button-row-field">
              <Button type="submit" color="secondary" variant="contained" disableElevation>
                {selectedUser ? "Guardar cambios" : "Verificar email"}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
      <CustomSnackbar
        open={creating && (auth.registerSuccess || auth.registerErrorMessage !== null)}
        message={auth.registerSuccess ? "Se creó el usuario correctamente" : "Ocurrió un error al crear el usuario"}
        handleClose={closeSnack}
        type={auth.registerSuccess ? 0 : 1}
      />
      <CustomSnackbar
        open={modifying && (auth.changePasswordSuccess || auth.changePasswordErrorMessage !== null)}
        message={
          auth.changePasswordSuccess
            ? "Se actualizó la contraseña correctamente"
            : "Ocurrió un error al actualizar la contraseña"
        }
        handleClose={closeSnack}
        type={auth.changePasswordSuccess ? 0 : 1}
      />
    </>
  );
}

export default connector(withRouter(UserForm));
