import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { currencyHelper } from '../../../helpers/currencyHelper';
import image from '../../../assets/blackLogo.png';
import { Donation, Moneybox } from '../../../store/config/types';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '50px 15px',
  },
  imageContainer: {
    width: '500px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 16,
    paddingBottom: 16,
  },
  image: {
    height: 60,
    width: 'auto',
  },
  content: {
    margin: '0',
    marginTop: '30px',
    width: '500px',
    height: '500px',
    fontSize: 14,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  details: {
    display: 'flex',
    flexGrow: 1,
    border: '1 solid #666',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    flexDirection: 'column',
    padding: 15,
  },
  singleLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 10,
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
  },
  separator: {
    margin: '15px',
    borderTop: '1 solid #666',
  },
  horizontalChunk: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  amountChunk: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  verticalChunk: {
    display: 'flex',
    flexDirection: 'column',
  },
  verticalLastChunk: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  smallText: {
    fontSize: 12,
    color: '#666',
    marginTop: 4,
    marginBottom: 4,
  },
  currency: {
    fontSize: 20,
    marginBottom: 2,
  },
  bigNumber: {
    marginLeft: 5,
    marginRight: 5,
    fontSize: 24,
  },
});

interface PDFReceiptProps {
  moneybox: Moneybox;
  donation: Donation;
}

function PDFReceipt({ moneybox, donation }: PDFReceiptProps) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <View style={styles.imageContainer}>
            <Image style={styles.image} source={image} />
          </View>
          <View style={styles.content}>
            <View style={styles.details}>
              <View style={styles.singleLine}>
                <Text>{'Donación realizada con éxito'}</Text>
              </View>
              <View style={styles.singleLine}>
                <Text>
                  Alcancía de{' '}
                  {moneybox && moneybox.fantasyName !== 'Generica' ? moneybox.fantasyName : 'Teletón'}
                </Text>
              </View>
              <View style={styles.separator} />
              {donation !== null && (
                <View style={styles.line}>
                  <View style={styles.verticalChunk}>
                    <Text style={styles.smallText}>Moneda</Text>
                    <Text>{currencyHelper.getCurrencyName(donation.currency)}</Text>
                  </View>
                  <View style={styles.verticalLastChunk}>
                    <View style={styles.amountChunk}>
                      <Text style={styles.currency}>
                        {currencyHelper.getCurrencyToken(donation.currency)}
                      </Text>
                      <Text style={styles.bigNumber}>{donation.value}</Text>
                    </View>
                  </View>
                </View>
              )}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default PDFReceipt;
