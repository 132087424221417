import { Button } from '@material-ui/core';
import React from 'react';
import { Formik, Form, Field } from 'formik';
import schemas from '../../../data/schemas';
import { Currency } from '../../../store/config/enums';
import { FormTextField } from '../../forms/FormTextField';
import { FormSelectField } from '../../forms/FormSelectField';
import { Values } from './types';

interface SpecificAmountOptionProps {
  submit(values: Values): void;
}

function SpecificAmountOption({ submit }: SpecificAmountOptionProps) {
  return (
    <div className="specific-amount">
      <p>O ingresá uno específico aquí.</p>
      <Formik
        initialValues={{ amount: '', currency: Currency.PESO }}
        onSubmit={submit}
        validationSchema={schemas.AmountSchema}
      >
        {({ isSubmitting, values }) => (
          <Form className="specific-amount-row">
            <Field
              className="specific-amount-field"
              name="amount"
              component={FormTextField}
              type="number"
              min="0"
            />
            <Field
              className="specific-amount-field"
              name="currency"
              component={FormSelectField}
              options={[
                { id: Currency.PESO, value: 'Pesos' },
                { id: Currency.DOLAR, value: 'Dólares' },
              ]}
            />
            <Button
              disabled={isSubmitting || !values['amount']}
              classes={{
                disabled: 'disabled-button',
                root: `donate-button ${values['currency'] === Currency.DOLAR ? 'donate-dollar-button' : ''}`,
              }}
              variant="contained"
              type="submit"
              disableElevation
            >
              Donar monto ingresado
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default SpecificAmountOption;
