import React from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { RouteComponentProps, withRouter } from "react-router-dom";

const questions = [
  {
    q: "¿Quien puede crear una alcancía Teletón?",
    a:
      "Cualquier persona que desee colaborar con la Fundación podrá ingresar al sitio de alcancías digitales (alcancias.teleton.org.uy) y crear una o más alcancías, que podrá consultar y gestionar desde su usuario.",
  },
  {
    q: "¿Cómo me aseguro de que el dinero recaudado en cada alcancía llega a Fundación Teletón?",
    a:
      "Cada alcancía está asociada directamente a la cuenta bancaria Santander de la Fundación por lo cual los fondos ingresan directamente.",
  },
  {
    q: "¿Hasta cuando tengo tiempo para crear una alcancía?",
    a: "No hay un plazo estipulado. Se pueden crear en cualquier momento.",
  },
  {
    q: "¿Que medios de pago puedo utilizar para pagar mi donación?",
    a:
      "Los medios de pago habilitados son las tarjetas de crédito Anda, Cabal, Creditel, Mastercard, OCA, PassCard, Tarjeta D, VISA.",
  },
];

function FAQs({ history }: RouteComponentProps) {
  const goBack = () => {
    history.goBack();
  };

  return (
    <div className="faqs">
      <div className="faqs-header">
        <Typography component="h1" variant="h4">
          Preguntas Frecuentes
        </Typography>
        <span className="blue-link" onClick={goBack}>
          Volver
        </span>
      </div>
      <div className="faqs-questions">
        {questions.map((q, i) => {
          return (
            <div key={i} className="faqs-question">
              <Typography component="h2" variant="h5">
                {q.q}
              </Typography>
              <Typography component="p" variant="body2">
                {q.a}
              </Typography>
              <Divider />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default withRouter(FAQs);
