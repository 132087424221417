import { IssuersState } from "../config/types";
import {
  IssuersDataTypes,
  LOADING_ISSUERS,
  ISSUERS_FAILED,
  ADD_ISSUERS,
  LogoutActionTypes,
  LOGOUT,
} from "../config/ActionTypes";

export const initialState: IssuersState = {
  loadingIssuers: false,
  issuers: null,
  issuersErrorMessage: null,
};

export function issuersReducer(
  state = initialState,
  action: IssuersDataTypes | LogoutActionTypes
): IssuersState {
  switch (action.type) {
    case LOADING_ISSUERS:
      return {
        ...state,
        loadingIssuers: true,
        issuers: null,
        issuersErrorMessage: null,
      };

    case ADD_ISSUERS:
      return {
        ...state,
        loadingIssuers: false,
        issuers: action.issuers,
        issuersErrorMessage: null,
      };

    case ISSUERS_FAILED:
      return {
        ...state,
        loadingIssuers: false,
        issuers: null,
        issuersErrorMessage: action.error,
      };

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
