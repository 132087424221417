import { get, post, httpDelete } from "./base.service";
import { endpoints } from "./endpoints";
import { MoneyboxRequest, Link, DeleteLinkRequest, ShareLinkRequest, LinkRequest } from "../store/config/types";

export const linksService = {
  fetchLinks,
  createLink,
  deleteLink,
  shareLink,
};

async function fetchLinks({ moneyboxId }: MoneyboxRequest): Promise<Link[] | undefined> {
  return await get<Link[]>(`${endpoints["moneyboxBase"]}/${moneyboxId}/links`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function createLink(linkRequest: LinkRequest): Promise<Link | undefined> {
  const request = {
    currency: linkRequest.currency,
    value: linkRequest.value,
  };

  return await post<Link>(`${endpoints["moneyboxBase"]}/${linkRequest.moneyboxId}/links`, request)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function deleteLink({ moneyboxId, linkId }: DeleteLinkRequest): Promise<boolean | undefined> {
  return await httpDelete<boolean>(`${endpoints["moneyboxBase"]}/${moneyboxId}/links/${linkId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function shareLink({ moneyboxId, linkId, via }: ShareLinkRequest): Promise<boolean | undefined> {
  return await post<boolean>(`${endpoints["moneyboxBase"]}/${moneyboxId}/links/${linkId}`, via)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
