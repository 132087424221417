import React, { useEffect } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Lottie } from '@crello/react-lottie';
import SettingsIcon from '@material-ui/icons/Settings';
import WarningIcon from '@material-ui/icons/Warning';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import Image from 'material-ui-image';
import { ThunkDispatch } from 'redux-thunk';
import { Document, PDFDownloadLink } from '@react-pdf/renderer';
import loadAnimation from '../../assets/redLoading.json';
import { MoneyboxLinks } from '../../components/dashboard/MoneyboxLinks';
import ReceiptPDF from '../../components/dashboard/ReceiptPDF/ReceiptPDF';
import { urlHelper } from '../../helpers/urlHelper';
import { RootState } from '../../store/config/types';
import { AppActions } from '../../store/config/ActionTypes';
import { fetchMoneyboxesTotals } from '../../store/action_creators/moneybox.actions';

const mapStateToProps = (state: RootState) => ({
  moneybox: state.moneybox,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  fetchMoneyboxesTotals: (businessName: string, from?: Date, to?: Date) =>
    dispatch(fetchMoneyboxesTotals(businessName, from, to)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function Dashboard({ moneybox, fetchMoneyboxesTotals, history }: PropsType) {
  const startOfYear: Date = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0);

  useEffect(() => {
    if (
      !moneybox.fetchingTotals &&
      !moneybox.totals &&
      !moneybox.fetchTotalsErrors &&
      moneybox.selectedMoneybox
    ) {
      fetchMoneyboxesTotals(moneybox.selectedMoneybox.name, startOfYear, new Date());
    }
  }, [
    moneybox.fetchingTotals,
    moneybox.totals,
    moneybox.fetchTotalsErrors,
    moneybox.selectedMoneybox,
    fetchMoneyboxesTotals,
    startOfYear,
  ]);

  return (
    <div className="dashboard">
      {moneybox.selectedMoneybox ? (
        <>
          {moneybox?.selectedMoneybox?.status === 99 && (
            <div className="disabled-warning">
              <WarningIcon color="primary" />
              Esta alcancía está inactiva. No podrás realizar cambios ni modificar sus links.
            </div>
          )}
          <div className="dashboard-heading">
            <div className="moneybox-info">
              <Image
                imageStyle={{ position: 'inherit', height: 80, width: 'auto' }}
                style={{ display: 'flex', paddingTop: 0 }}
                src={urlHelper.buildImageUrl(moneybox.selectedMoneybox.id)}
                alt="alcancia"
              />
              <p className="name">{moneybox.selectedMoneybox.fantasyName}</p>
              <div className="links-row">
                <p className="blue-link icon-blue-link" onClick={() => history.push('/moneybox')}>
                  <SettingsIcon className="icon" fontSize="inherit" /> Modificar información de la alcancía
                </p>
                {moneybox.totals && (
                  <p className="blue-link icon-blue-link">
                    <PDFDownloadLink
                      document={
                        <Document>
                          <ReceiptPDF
                            selectedMoneybox={moneybox.selectedMoneybox}
                            totals={moneybox.totals}
                            fromDate={startOfYear}
                            toDate={new Date()}
                          />
                        </Document>
                      }
                      fileName={moneybox.selectedMoneybox.name}
                    >
                      <DownloadIcon className="icon" fontSize="inherit" /> Descargar recibo
                    </PDFDownloadLink>
                  </p>
                )}
              </div>
            </div>
            <div className="moneybox-total">
              <p className="total-speech">Totales donados hasta el momento por la alcancía</p>
              <div className="total-amounts">
                <span className="currency">$</span>
                <span className="total">{moneybox.selectedMoneybox.raisedAmount}</span>
                <span className="currency">US$</span>
                <span className="total">{moneybox.selectedMoneybox.raisedAmountDollars}</span>
              </div>
            </div>
          </div>

          <MoneyboxLinks existingLinks={moneybox.selectedMoneybox.links!} />
        </>
      ) : moneybox.loadingMoneyboxes ? (
        <div className="loading">
          <Lottie height="200px" width="200px" config={{ animationData: loadAnimation, loop: true }} />
        </div>
      ) : (
        moneybox.moneyboxes && moneybox.moneyboxes.length <= 0 && <Redirect to="/newMoneybox" />
      )}
    </div>
  );
}

export default connector(Dashboard);
